<template>
  <div class="py-4 px-4 md:px-0 w-full lighten-1">
    <SettingsUserCard
      v-for="(user, i) in users"
      :key="`user-${user.uuid}`"
      :uuid="user.uuid"
      :name="user.name"
      :email="user.email"
      :role="user.role"
      :enabled="user.enabled === 1"
      :locations="user.locations"
    />
    <v-dialog
        v-model="dialog"
        hide-overlay
        max-width="30%"
        persistent
    >
      <div class="relative w-full gray lighten-1 pa-lg-5">
        <v-btn
            class="absolute"
            icon
            style="top: 8px; right: 8px;"
            @click="$emit('close-dialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="title font-weight-bold">Add User</div>

        <div class="flex flex-col items-center pt-4">
          <v-text-field
              label="Name"
              ref="userName"
              class="userNameField"
              inline
              outlined
              aria-required="true"
              v-model="userName"
          />
          <v-text-field
              label="Email"
              ref="userEmail"
              class="userEmailField"
              inline
              outlined
              aria-required="true"
              v-model="userEmail"
          />
          <v-text-field
              label="Password"
              ref="profilePasswordInput"
              class="userPasswordFields"
              :type="passwordFieldShow ? 'text' : 'password'"
              inline
              outlined
              :color="passwordColor"
              v-model="profilePassword"
              :append-icon="passwordFieldShow ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="passwordFieldShow = !passwordFieldShow"
              @focus="appendIconPassword = 'mdi-eye'"
              :hint="passwordFieldHint"
              :persistent-hint="!!passwordFieldHint"
              @blur="appendIconPassword = undefined"
          ></v-text-field>

          <v-text-field
              v-if="profilePassword !== profilePasswordDefault"
              label="Confirm Password"
              ref="profilePasswordConfirmationInput"
              class="userPasswordFields"
              :type="passwordFieldShow ? 'text' : 'password'"
              inline
              outlined
              :color="passwordConfirmationColor"
              v-model="profilePasswordConfirmation"
              :append-icon="passwordFieldShow ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="passwordFieldShow = !passwordFieldShow"
              @focus="appendIconPassword = 'mdi-eye'"
              :hint="passwordConfirmationFieldHint"
              :persistent-hint="!!passwordConfirmationFieldHint"
              @blur="appendIconPassword = undefined"
          ></v-text-field>

          <v-select
              outlined
              class="userSelectsField"
              :items="rolesForSelect"
              item-text="title"
              item-value="title"
              v-model="userRole"
              label="Permission"
              aria-required="true"
              placeholder="Select user permission"
          />
          <v-select
              outlined
              class="userSelectsField"
              multiple
              :items="locationsAll"
              item-text="name"
              item-value="uuid"
              v-model="userLocations"
              label="Locations"
              aria-required="true"
              placeholder="Select user locations"
          />


          <div class="w-full flex justify-end">
            <v-btn
                color="#2F6B93"
                style="color: white; float: right"
                @click="addNewUser"
            >
              <div class="flex items-center">
                <span class="mr-1">ADD</span>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SettingsUserCard from '@/components/cards/SettingsUserCard.vue';
import {mapState} from "vuex";


export default {
  name: "UsersSettings",
  components: { SettingsUserCard },
  data() {
    return {
      dialog: false,
      userName: undefined,
      userEmail: undefined,
      userRole: undefined,
      userLocations: undefined,
      passwordFieldShow: false,
      passwordFieldHint: 'At Least 6 symbols',
      passwordConfirmationFieldHint: undefined,
      profilePasswordDefault: 'Password123!',
      profilePassword: 'Password123!',
      profilePasswordConfirmation: undefined,
      passwordColor: '#056C97',
      passwordConfirmationColor: '#056C97',
    }
  },
  props: {
    createUser: { type: Boolean, default: false }
  },
  watch: {
    createUser() {
      this.dialog = this.createUser;
    }
  },
  computed: {
    ...mapState({
      users: state => state.users,
      locationsAll: state => state.locationsAll,
      currentUser: state => state.auth.user,
    }),
    roles() {
      return [
        {level: 1, title: 'Support'},
        {level: 2, title: 'Practitioner'},
        {level: 3, title: 'Manager'},
        {level: 4, title: 'Administrator'},
      ]
    },
    rolesForSelect() {
      return this.roles.filter( (role) => role.level <= this.currentUserRoleLevel);
    },
    currentUserRoleLevel() {
      return this.roles.filter(role => role.title === this.currentUser.role)[0].level;
    },
  },
  mounted() {
    this.$store.dispatch('GET_ALL_LOCATIONS')
    this.$store.dispatch('ALL_USERS')
  },
  methods: {
    async addNewUser() {

      let passwordConfirmed = false

      if (this.profilePassword === this.profilePasswordDefault) {
        passwordConfirmed = !passwordConfirmed
      }

      if (this.profilePassword.length < 6) {
        this.$refs.profilePasswordInput.focus()
        this.passwordFieldHint = "Password should contains at least 6 symbols!"
        this.passwordColor = 'red darken-1'
        return false
      }

      if (!passwordConfirmed && this.profilePassword !== this.profilePasswordConfirmation) {
        this.$refs.profilePasswordConfirmationInput.focus()
        this.passwordConfirmationFieldHint = "Password confirmation does not match"
        this.passwordConfirmationColor = 'red darken-1'

        return false
      }

      const payload = {
        data: {
          name: this.userName,
          email: this.userEmail,
          role: this.userRole,
          offices: this.userLocations,
          password: this.profilePassword,
          password_confirmation: passwordConfirmed ? this.profilePassword : this.profilePasswordConfirmation,
        }
      }

      const success = await this.$store.dispatch('USER_CREATE', payload)

      if (success) {
        this.clearForm()
        this.$emit('close-dialog')
        this.$eventBus.$emit('emit-success', { state: true, text: `${this.userName} created! .` });
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while creating user ${this.userName}.` });
      }

    },
    clearForm() {
      this.userName = undefined
      this.userEmail = undefined
      this.userRole = undefined
      this.userLocations = undefined
      this.profilePassword = undefined
      this.profilePasswordConfirmation = undefined
    }
  }
}
</script>

<style scoped>
.userNameField,
.userEmailField,
.userSelectsField, .userPasswordFields {
  width: 100%;
}

</style>
