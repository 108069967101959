




















































































































































































































































import { boolean } from 'boolean';
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import randomColor from 'randomcolor';
import AnalyticsDialog from '@/components/modules/AnalyticsDialog.vue';
import AppointmentDialog from '@/components/modules/AppointmentDialog.vue';
import ConfirmationDialog from '@/components/modules/ConfirmationDialog.vue';
import NotesDialog from '@/components/modules/NotesDialog.vue';
import SettingsDialog from '@/components/modules/SettingsDialog.vue';
import Dashboard from '@/components/modules/Dashboard.vue';
import WaitingRoom from "@/components/modules/WaitingRoom.vue";
import VirtualKeyboard from '@/components/modules/VirtualKeyboard.vue';
import ProfileSettingsDialog from '@/components/modules/ProfileSettingsDialog.vue';
import MobileMenu from '@/components/modules/MobileMenu.vue';


export default Vue.extend({
  name: 'Home',
  components: {
    VirtualKeyboard,
    AnalyticsDialog,
    AppointmentDialog,
    ConfirmationDialog,
    NotesDialog,
    Dashboard,
    SettingsDialog,
    WaitingRoom,
    ProfileSettingsDialog,
    MobileMenu,
  },
  data() {
    return {
      direction: 'top',
      errorState: false,
      errorStateText: 'Something went wrong. Please try again.',
      fab: false,
      compactSpeedDial: false,
      filter: null,
      filtered: false,
      showAnalytics: false,
      showNotes: false,
      showProfileSettings: false,
      showSettings: false,
      successState: false,
      successStateText: '',
      viewBoard: false,
      viewAnalytics: false,
      configureBoard: false,
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      user: state => state.auth.user,
      // @ts-ignore
      doctors: state => state.doctors,
      // @ts-ignore
      showAppointment: state => state.showAppointment,
      // @ts-ignore
      confirmation: state => state.confirmation,
      // @ts-ignore
      dashboard: state => state.auth.dashboard,
      // @ts-ignore
      office: state => state.auth.office,
      // @ts-ignore
      offices: state => state.auth.user?.offices,
      // @ts-ignore
      showVirtualKeyboard: state => state.showVirtualKeyboard,
      // @ts-ignore
      newWindowOpen: state => state.newWindowOpen
    }),
    ...mapGetters(['queue', 'queueUpcoming', 'queueTomorrow']),
    doctorsFormatted() {
      if (!this.doctors) return [];
      return this.doctors
        .filter(({ is_enabled: x }) => boolean(x))
        .map(d => {
          const name = d.name;
          const initials = name.split(' ').map((n) => n[0]).join('');
          return {
            ...d,
            initials: d.name.split(' ').map((n) => n[0]).join(''),
          }
        })
    },
    selectedOfficeName() {
      const title = this.offices.filter( eachOffice => eachOffice.uuid === this.office )[0].name
      console.log('title office lenght', title.length)
      return (title.length > 35) ? title.substr(0, 35-1) + '...' : title;
    },
    isCompactWindow() {
      return window.innerWidth <= 1162;
    }
  },
  async created() {

    // Attach event listeners
    this.$eventBus.$on('emit-success', this.setSuccessState)
    this.$eventBus.$on('emit-error', this.setErrorState)

    // Populate queue, Rooms, Doctors, and Treatments
    if (!!this.user) {
      this.LOAD_APPOINTMENTS()
      this.LOAD_DASHBOARD()
      this.LOAD_DOCTORS()
      this.LOAD_TREATMENTS()

      this.viewBoard = await this.$store.dispatch('ableUserTo', 'view board')
      this.viewAnalytics = await this.$store.dispatch('ableUserTo','view avanalytics')
      this.configureBoard = await this.$store.dispatch('ableUserTo','configure board')
    }

    if (window.name === '') {
      await this.REGISTER_OPEN_NEW_WINDOW();
    }


    // Refresh store data when other clients make changes
    this.SUBSCRIBE_TO_CLIENT_RELOAD({uuid: this.office})
  },
  beforeDestroy() {
    // Detach event listeners
    this.$eventBus.$off('emit-success');
    this.$eventBus.$off('emit-error');

    // Detach queue refresh
    clearInterval(this.queueRefreshInterval)
    this.queueRefreshInterval = null

    // Detach event listeners
    this.UNSUBSCRIBE_ALL()
  },
  methods: {
    ...mapActions([
      'CLEAR_HISTORY',
      'LOAD_APPOINTMENTS',
      'LOAD_DASHBOARD',
      'LOAD_DOCTORS',
      'LOAD_TREATMENTS',
      'SUBSCRIBE_TO_CLIENT_RELOAD',
      'UNSUBSCRIBE_ALL',
      'REGISTER_OPEN_NEW_WINDOW'
    ]),
    ...mapMutations(['setFilter']),
    updateReloadAfterNewOpen(){
      window.name = '';
      window.onbeforeunload = null
      document.location.reload();
    },
    filterDashboard(doctor) {
      this.filter = doctor.uuid
      this.filtered = true
      this.setFilter({ filter: doctor.uuid })
    },
    initials(value: string): string {
      if (!value) return ''
      return value.split(" ").map((n: string) => n[0]).join("")
    },
    async onOfficeSelected(uuid: string) {
      this.CLEAR_HISTORY()
      this.UNSUBSCRIBE_ALL()
      this.$store.commit('setOffice', { data: uuid })
      await Promise.all([
        this.LOAD_APPOINTMENTS(),
        this.LOAD_DASHBOARD(),
        this.LOAD_DOCTORS(),
        this.LOAD_TREATMENTS(),
        this.SUBSCRIBE_TO_CLIENT_RELOAD({uuid})
      ])
    },
    onShowSettings() { this.showSettings = true },
    randomColor() { return randomColor() },
    resetFilter() {
      this.filter = null
      this.filtered = false
      this.setFilter({ filter: '' })
    },
    setErrorState({ state, text }) {
      this.errorState = state
      if (text || text === '') this.errorStateText = text
      else this.errorStateText = 'Something went wrong. Please try again.'
    },
    setSuccessState({ state, text }) {
      this.successState = state
      this.successStateText = text
    },
    toggleKeyboard(){
      this.$store.dispatch("toggleKeyboard");
    }
  }
})
