











































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  name: 'SettingsRoomCard',
  props: {
    room: Object,
    roomColor: String,
  },
  data() {
    return {
      appendIcon: undefined,
      roomNameHint: undefined,
      roomNameColor: undefined,
      roomName: '',
      enabled: null,
    };
  },
  computed: mapState(['confirmation']),
  watch: {
    confirmation({ uuid, wasConfirmed }) {
      if (uuid===this.room.uuid && wasConfirmed !== null) {
        this.$store.dispatch('RESET_CONFIRMATION')
        if (wasConfirmed) this.deleteRoom()
      }
    }
  },
  created() {
    this.roomName = this.room.name;
    this.enabled = this.room.is_enabled === 'yes';
  },
  methods: {
    ...mapActions([
      'ASK_FOR_CONFIRMATION',
      'RESET_CONFIRMATION'
    ]),
    async removeTreatment(uuid) {
      const payload = {
        roomId: this.room.uuid,
        status: 'remove',
        params: { treatment: uuid }
      };
      const success = await this.$store.dispatch('UPDATE_ROOM_TREATMENT', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Removed treatment from room.' });
        await this.$store.dispatch('LOAD_ROOMS');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    async updateRoomName(event) {
      console.log({ event });
      const payload = {
        roomId: this.room.uuid,
        params: { name: this.roomName }
      };
      const success = await this.$store.dispatch('UPDATE_ROOM_SETTINGS', payload);
      if (success) {
          this.roomNameHint = "Updated room's name";
          this.roomNameColor = 'green darken-1';
          await this.$store.dispatch('LOAD_ROOMS');
          this.roomName = this.room.name;
          await this.$store.dispatch('LOAD_DASHBOARD');
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }

      setTimeout(() => {
        this.resetRoomNameInput();
      }, 1500);
    },

    async toggleRoomStatus(enabled) {
      const payload = {
        status: enabled ? 'enable' : 'disable',
        roomId: this.room.uuid,
      }
      const success = await this.$store.dispatch('UPDATE_ROOM_STATUS', payload);
      await this.$store.dispatch('LOAD_ROOMS');
      await this.$store.dispatch('LOAD_DASHBOARD');
    },

    resetRoomNameInput() {
      this.roomNameHint = undefined;
      this.roomNameColor = undefined;
      this.$eventBus.$emit('emit-error', { state: false, text: '' });
      this.$refs.roomNameInput.blur();
    },

    showConfirmation() {
      this.ASK_FOR_CONFIRMATION({
        message: 'This room will be deleted forever',
        uuid: this.room.uuid
      });
    },

    async deleteRoom() {
      const payload = {
        roomId: this.room.uuid,
        params: { user: this.$store.state.auth.user.uuid }
      }
      const success = await this.$store.dispatch('DELETE_ROOM', payload);
      if (success) {
        await Promise.all([
          this.$store.dispatch('LOAD_ROOMS'),
          this.$store.dispatch('LOAD_DASHBOARD')
        ])
        this.roomName = this.room.name;
        this.enabled = this.room.is_enabled === 'yes';
        this.$eventBus.$emit('emit-success', { state: true, text: 'Deleted' });
        setTimeout(() => {
          this.$eventBus.$emit('emit-success', { state: false, text: '' });
        }, 1200);
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    },
  }
})
