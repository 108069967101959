
























































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  name: 'SettingsDoctorCard',
  props: {
    doctor: Object,
    doctorColor: String,
  },
  data() {
    return {
      appendIcon: undefined,
      doctorNameHint: undefined,
      doctorNameColor: undefined,
      doctorName: '',
      enabled: null,
    };
  },
  computed: mapState(['confirmation']),
  watch: {
    confirmation({ uuid, wasConfirmed }) {
      if (uuid===this.doctor.uuid && wasConfirmed !== null) {
        this.$store.dispatch('RESET_CONFIRMATION')
        if (wasConfirmed) this.deleteDoctor()
      }
    }
  },
  created() {
    this.doctorName = this.doctor.name;
    this.enabled = this.doctor.is_enabled === 'yes';
  },
  methods: {
    ...mapActions([
      'ASK_FOR_CONFIRMATION',
      'RESET_CONFIRMATION'
    ]),
    async removeTreatment(uuid) {
      const payload = {
        doctorId: this.doctor.uuid,
        status: 'remove',
        params: { treatment: uuid }
      };
      const success = await this.$store.dispatch('UPDATE_DOCTOR_TREATMENT', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Removed treatment from practitioner.' });
        await this.$store.dispatch('LOAD_DOCTORS');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    async updateDoctorName(event) {
      console.log({ event });
      const payload = {
        doctorId: this.doctor.uuid,
        params: { name: this.doctorName }
      };
      const success = await this.$store.dispatch('UPDATE_DOCTOR_SETTINGS', payload);
      if (success) {
          this.doctorNameHint = "Updated practitioner's name";
          this.doctorNameColor = 'green darken-1';
          await this.$store.dispatch('LOAD_DOCTORS');
          await this.$store.dispatch('LOAD_DASHBOARD');
          this.doctorName = this.doctor.name;
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }

      setTimeout(() => {
        this.resetDoctorNameInput();
      }, 1500);
    },

    async toggleDoctorStatus(enabled) {
      const payload = {
        status: enabled ? 'enable' : 'disable',
        doctorId: this.doctor.uuid,
      }
      const success = await this.$store.dispatch('UPDATE_DOCTOR_STATUS', payload);
      await this.$store.dispatch('LOAD_DOCTORS');
    },

    resetDoctorNameInput() {
      this.doctorNameHint = undefined;
      this.doctorNameColor = undefined;
      this.$eventBus.$emit('emit-error', { state: false, text: '' });
      this.$refs.doctorNameInput.blur();
    },

    showConfirmation() {
      this.ASK_FOR_CONFIRMATION({
        message: 'This practitioner will be deleted forever',
        uuid: this.doctor.uuid
      });
    },

    async deleteDoctor() {
      const payload = {
        doctorId: this.doctor.uuid,
        params: { user: this.$store.state.auth.user.uuid }
      }
      const success = await this.$store.dispatch('DELETE_DOCTOR', payload);
      if (success) {
        await this.$store.dispatch('LOAD_DOCTORS');
        await this.$store.dispatch('LOAD_DASHBOARD');
        this.doctorName = this.doctor.name;
        this.enabled = this.doctor.is_enabled === 'yes';
        this.$eventBus.$emit('emit-success', { state: true, text: 'Deleted' });
        setTimeout(() => {
          this.$eventBus.$emit('emit-success', { state: false, text: '' });
        }, 1200);
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    },
  }
})
