


















































































































































import Vue from 'vue';
import { mapState } from 'vuex';
import SettingsDialogDoctors from '@/components/modules/SettingsDialogDoctors.vue';
import SettingsDialogPractice from '@/components/modules/SettingsDialogPractice.vue';
import SettingsDialogTreatments from '@/components/modules/SettingsDialogTreatments.vue';

export default Vue.extend({
  name: 'SettingsDialog',
  components: {
    SettingsDialogDoctors,
    SettingsDialogPractice,
    SettingsDialogTreatments
  },
  data() {
    return {
      activeTab: 'doctors',
      showAddItem: false,
      input: {
        doctorName: '',
        doctorTreatments: [],
        roomName: '',
        roomTreatments: [],
        treatmentName: '',
        treatmentFullName: '',
        treatmentDuration: '30',
        treatmentRooms: [],
      },
      defaults: {
        doctorName: '',
        doctorTreatments: [],
        roomName: '',
        roomTreatments: [],
        treatmentName: '',
        treatmentFullName: '',
        treatmentDuration: '30',
        treatmentRooms: [],
      },
      rules: {
        required: value => !!value || 'Required.',
        maxLength: value => value.length <= 30 || 'Max 30 characters',
        maxShort: value => value.length <= 6 || 'Max 6 characters'
      }
    };
  },
  computed: {
    ...mapState({
      doctors: (state: any) => state.doctors,
      rooms: (state: any) => state.rooms,
      treatments: (state: any) => state.treatments,
    }),
    newDoctor() {
      if (this.activeTab !== 'doctors') return null;
      return {
        office: this.$store.state.auth.office,
        name: this.input.doctorName,
        treatments: this.input.doctorTreatments.length ? this.input.doctorTreatments : [],
      }
    },
    newRoom() {
      if (this.activeTab !== 'rooms') return null;
      return {
        office: this.$store.state.auth.office,
        name: this.input.roomName,
        treatments: this.input.roomTreatments.length ? this.input.roomTreatments : [],
      }
    },
    newTreatment() {
      if (this.activeTab !== 'treatments') return null;
      return {
        office: this.$store.state.auth.office,
        full_name: this.input.treatmentFullName,
        name: this.input.treatmentName,
        duration: this.input.treatmentDuration,
        rooms: this.input.treatmentRooms.length ? this.input.treatmentRooms : [],
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('LOAD_DOCTORS'),
        this.$store.dispatch('LOAD_TREATMENTS'),
        this.$store.dispatch('LOAD_ROOMS'),
        this.$store.dispatch('LOAD_DASHBOARD')
      ])
    },
    async createItem() {
      const options = {
        doctors: this.newDoctor,
        rooms: this.newRoom,
        treatments: this.newTreatment
      }
      const actions = {
        doctors: 'CREATE_DOCTOR',
        rooms: 'CREATE_ROOM',
        treatments: 'CREATE_TREATMENT'
      }
      const payload = { params: options[this.activeTab] };
      const success = await this.$store.dispatch(actions[this.activeTab], payload);
      if (success) {
        this.resetNewItems();
        this.$eventBus.$emit('emit-success', { state: true, text: 'Created new item.' });
        await this.fetchData();
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    },
    resetNewItems() {
      this.input = Object.assign(this.input, this.defaults);
      this.showAddItem = false;
    },
  }
});
