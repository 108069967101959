
























import AppointmentTreatmentSelector from '@/components/modules/AppointmentTreatmentSelector.vue';
import TreatmentDetailSelector from '@/components/modules/TreatmentDetailSelector.vue';

import Vue from 'vue';
import {mapState} from 'vuex';

export default Vue.extend({
  name: 'AppointmentDialog',
  components: {
    AppointmentTreatmentSelector,
    TreatmentDetailSelector
  },
  data() {
    return {
      dialog: true,
      step: 0,
      interval: undefined,
      count: 0,
    }
  },
  created() {
    if (!this.interval) this.interval = setInterval(() => {
      this.count++;
    }, 30)
  },
  watch: {
    count() {
      if (this.count > 25 && this.dialog) {
        clearInterval(this.interval);
      }
    },
  },
  computed: {
    ...mapState(['selectedPatient'])
  },
  methods: {
    onShowSettings() {
      this.$emit('on-show-settings');
    },
    closeAppointment() {
      if (this.count > 20) {
        this.$store.dispatch('resetAppointment')
        clearInterval(this.interval);
      }
    }
  }
})
