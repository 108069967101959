<template>
  <div v-cloak class="home w-full h-full p-4">
    <TreatmentCard
      initials="TT"
      title="MTx"
      :initial-allocated-time="allocatedTime"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import TreatmentCard from '@/components/cards/TreatmentCard.vue';

export default Vue.extend({
  name: 'Sandbox',
  components: { TreatmentCard },
  data() {
    return {
      allocatedTime: 0.5 * 60 * 1000,
    }
  }
})
</script>

<style scoped>
[v-cloak] {
  display: none;
}
</style>
