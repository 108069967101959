<template>
  <div class="py-4 px-4 md:px-0 w-full lighten-1">
    <SettingsLocationCard
        v-for="(location, i) in locationAllForSelect"
        :key="`location-${location.uuid}`"
        :uuid="location.uuid"
        :name="location.name"
        :address="location.address"
        :timeZone="location.timeZone"
        :enabled="location.enabled === 1"
        :offices="location.offices"
        :practitionersCount="location.practitioners_count"
        :roomsCount="location.rooms_count"
        :treatmentTypesCount="location.treatment_types"
        :createdAt="location.created_at"
    />
    <v-dialog
        v-model="dialog"
        hide-overlay
        max-width="30%"
        persistent
    >
      <div class="relative w-full gray lighten-1 pa-lg-5">
        <v-btn
            class="absolute"
            icon
            style="top: 8px; right: 8px;"
            @click="$emit('close-dialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="title font-weight-bold">Add Location</div>

        <div class="flex flex-col items-center pt-4">
          <v-text-field
              label="Name"
              ref="locationName"
              class="locationNameField"
              inline
              outlined
              hide-details="auto"
              aria-required="true"
              v-model="locationName"
          />
          <VueGoogleAutocomplete
              :id="`newLocationAddress`"
              label="Address"
              ref="locationAddress"
              :country="['us']"
              :fields="fieldsForAutocomplete"
              classname="locationAddressField pl-2 mt-2 mb-2"
              placeholder="Address"
              @inputChange="onLocationInputValChange"
              v-on:placechanged="onLocationInputChange">
          </VueGoogleAutocomplete>

          <div>
            <span
                class="text-gray text-xs pr-10"
                v-if="!!locationTimeZone"
            >
              Time zone:
              <b>{{ locationTimeZone }}</b>
            </span>
          </div>

          <v-select
              outlined
              class="locationSelectsField"
              multiple
              :items="practicesAll"
              item-text="name"
              item-value="uuid"
              v-model="locationOffices"
              label="Locations"
              aria-required="true"
              placeholder="Select offices"
          />


          <div class="w-full flex justify-end">
            <v-btn
                color="#2F6B93"
                style="color: white; float: right"
                @click="addNewLocation"
            >
              <div class="flex items-center">
                <span class="mr-1">ADD</span>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SettingsLocationCard from "@/components/cards/SettingsLocationCard";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Timezone from "node-google-timezone";

export default {
  name: "LocationSettings",
  components: { SettingsLocationCard, VueGoogleAutocomplete },
  props: {
    createLocation: { type: Boolean, default: false }
  },
  data() {
    return {
      dialog: false,
      locationName: undefined,
      locationAddress: undefined,
      locationTimeZone: undefined,
      locationOffices: undefined,
      locationAllForSelect: undefined,
      fieldsForAutocomplete:["address_components", "geometry", "icon", "name"],
    }
  },
  computed: {
    ...mapState({
      locationsAll: state => state.locationsAll,
      practicesAll: state => state.practicesAll,
    }),
  },
  mounted() {
    this.$store.dispatch('GET_ALL_LOCATIONS')
  },
  watch: {
    createLocation() {
      this.dialog = this.createLocation;
    },
    locationsAll() {
      this.locationAllForSelect = this.locationsAll;
    },
  },
  methods: {
    async addNewLocation() {
      const payload = {
        locationUUID: this.uuid,
        data: {
          name: this.locationName,
          address: this.locationAddress,
          timeZone: this.locationTimeZone,
          offices: this.locationOffices ?? [],
        }
      }

      const success = await this.$store.dispatch('LOCATION_CREATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${this.locationName} created! .` });
        this.clearForm()
        this.$emit('close-dialog')
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while creating location on ${this.locationAddress}.` });
      }

    },
    async onLocationInputChange(data) {
      this.locationAddressObject = data
      console.log(data)

      try {
        Timezone.key(process.env.VUE_APP_GOOGLE_TIME_STAMP_API_KEY);
        Timezone.data(data.latitude, data.longitude, 0, (err, tz) => {
          console.log(tz.raw_response);
          console.log(tz.local_timestamp);

          this.locationTimeZone = tz.raw_response.timeZoneId
        })
      } catch (error) {
        console.log(error)
        this.$eventBus.$emit('emit-error', { state: true, text:"Error handled while getting location's timezone!"})
      }
    },
    onLocationInputValChange(data) {
      this.locationAddress = data.newVal
    },
    clearForm() {
      this.locationName = undefined
      this.locationAddress = undefined
      this.locationOffices = undefined
      document.getElementById('newLocationAddress').value = ''
      this.locationTimeZone = undefined
    }
  }
}
</script>

<style scoped>
.locationNameField,
.locationAddressField,
.locationSelectsField {
  width: 100%;
}

.locationAddressField {
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  padding: 15px 20px;
}
</style>
