


























































































import Vue from 'vue';

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      logo: require('@/assets/images/prestige-logo.png'),
      valid: false,
      email: '',
      password: '',
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        // (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v: string) => !!v || 'Password is required',
      ],
      validating: false,
      errors: [],
      dialog: true,
      office: null,
      invalidError: 'Invalid credentials. Please try again.',
      defaultError: 'Something went wrong. Please try again.'
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    authError(): never | any {
      return {
        error: !!this.$store.state.auth.authenticationError,
        msg: this.$store.state.auth.authenticationError,
        code: this.$store.state.auth.authenticationErrorCode
      }
    }
  },
  // Using events to sign in on enter b/c @submit not working on v-form
  mounted() { window.addEventListener('keyup', this.onWindowKeyUp) },
  beforeDestroy() { window.removeEventListener('keyup', this.onWindowKeyUp) },
  methods: {
    async handleSelectOffice(): Promise<void> {
      this.$store.commit('setOffice', { data: this.office.uuid });
      const success = await this.$store.dispatch(
        'LOAD_DASHBOARD',
        { uuid: this.office.uuid, user: this.user.uuid }
      );
      if (!success) {
        this.errors = [this.defaultError];
        return;
      }
      this.$router.push({
        // @ts-ignore
        path: this.$router.history.current.query.redirect || '/'
      });
    },
    async handleLogin(): Promise<void> {
      this.validating = true;
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        const data = { email: this.email, password: this.password };
        const loggedIn = await this.$store.dispatch('authenticate', data);
        if (!loggedIn) {
          this.validating = false;
          console.log({ error: this.authError });
          if (this.authError.code === 400) {
            this.errors = [this.invalidError];
          } else {
            this.errors = [this.defaultError];
          }
          this.$progress.done();
          return;
        }
        this.validating = false;
      }
    },
    // Using events to sign in on enter b/c @submit not working on v-form
    onWindowKeyUp({ key }: KeyboardEvent) {
      if (key === 'Enter') this.handleLogin()
    }
  }
});
