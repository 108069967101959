






































import Vue from 'vue';
import randomColor from 'randomcolor';
import Avatar from '@/components/basic/Avatar.vue';

export default Vue.extend({
  name: 'PatientListItem',
  components: { Avatar },
  props: {
    imageUrl: { type: String, required: true },
    initialsName: { type: String, required: false },
    initialsColor: { type: String, required: false },
    initialsItemDoctors: { type: Array, required: false },
    noPadding: { type: Boolean, default: false },
    timestamp: { type: [Date, String], required: true },
    title: { type: String, required: true },
  },
  computed: {
    initials() {
      return this.$options.filters.initials(this.initialsName ?? 'X X')
    },
    initialsStyle() {
      const backgroundColor =
        this.initialsColor ?? (this.initials === 'XX'
          ? this.$vuetify.theme.themes.light.red
          : randomColor()
        )
      return {
        backgroundColor,
        color: this.$options.filters.foregroundColor(backgroundColor)
      }
    }
  },
  methods: {
    doctorStyle(doctor){
      const backgroundColor = doctor?.doctor.color ?? randomColor()
      return {
        backgroundColor,
        color: this.$options.filters.foregroundColor(backgroundColor)
      }
    }
  }
});
