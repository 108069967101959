




































































































































import Vue from "vue";
import {boolean} from 'boolean';
import {mapActions, mapState} from 'vuex';

export default Vue.extend({
  name: "MobileMenu",
  props: {
    compactMenuOpen: { type: Boolean, default: false },
  },
  async mounted() {
    this.viewAnalytics = await this.$store.dispatch('ableUserTo','view avanalytics')
    this.configureBoard = await this.$store.dispatch('ableUserTo','configure board')
  },
  data(){
    return {
      menuOpen: false,
      viewAnalytics: false,
      configureBoard: false,
      filterByDoctor: undefined,
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      doctors: state => state.doctors,
      // @ts-ignore
      office: state => state.auth.office,
      // @ts-ignore
      offices: state => state.auth.user?.offices,
    }),
    doctorsFormatted() {
      if (!this.doctors) return [];
      const doctors = []

      if (!!this.filterByDoctor) {
        doctors.push({uuid: 'resetDoctors', name: 'All Doctors'})
      }

      this.doctors
        .filter(({ is_enabled: x }) => boolean(x))
        .map(d => {
          const name = d.name;
          const initials = name.split(' ').map((n) => n[0]).join('');
          doctors.push({
            ...d,
            initials,
          })
        })

      return doctors
    },
  },
  watch: {
    compactMenuOpen() {
      this.menuOpen = this.compactMenuOpen
    },
    menuOpen() {
      if (this.menuOpen !== this.compactMenuOpen) {
        this.$emit('compact-speed-deal')
      }
    },
  },
  methods: {
    ...mapActions([
      'CLEAR_HISTORY',
      'LOAD_APPOINTMENTS',
      'LOAD_DASHBOARD',
      'LOAD_DOCTORS',
      'LOAD_TREATMENTS',
      'SUBSCRIBE_TO_CLIENT_RELOAD',
      'UNSUBSCRIBE_ALL',
      'REGISTER_OPEN_NEW_WINDOW'
    ]),
    toggleKeyboard(){
      this.$store.dispatch("toggleKeyboard");
    },
    resetDoctorFilter() {
      this.filterByDoctor = undefined
      this.$emit('reset-doctor-filter')
      this.menuOpen = false;
    },
    onDoctorFilterChange() {

      if (this.filterByDoctor === 'resetDoctors') {
        this.resetDoctorFilter()
        return false
      }

      const selectedDoctor = this.doctorsFormatted.filter( doc => doc.uuid === this.filterByDoctor )[0]
      this.$emit('filter-by-doctor', selectedDoctor)
      this.menuOpen = false;
    },
    async onOfficeSelected(uuid: string) {
      this.CLEAR_HISTORY()
      this.UNSUBSCRIBE_ALL()
      this.$store.commit('setOffice', { data: uuid })
      await Promise.all([
        this.LOAD_APPOINTMENTS(),
        this.LOAD_DASHBOARD(),
        this.LOAD_DOCTORS(),
        this.LOAD_TREATMENTS(),
        this.SUBSCRIBE_TO_CLIENT_RELOAD({uuid})
      ])
    },
  },
})
