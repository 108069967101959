





























































































import TreatmentDialog from '@/components/modules/TreatmentDialog.vue';
import {mdiCheckboxMarkedCircleOutline, mdiMotionPlayOutline, mdiPlay} from '@mdi/js';
import Vue from 'vue';
import {mapActions, mapState} from 'vuex';
import randomColor from 'randomcolor';
import store from '@/store';

declare interface BaseData {
  checkIcon: any,
  showDialog: boolean,
  interval: number | undefined,
  count: number,
  interactWithBoard: boolean,
}

export default Vue.extend({
  name: 'TreatmentCard',
  components: { TreatmentDialog },
  props: {
    id: { type: String, required: true },
    appointment: { type: Object, required: true },
    treatment: { type: Object, required: true },
    room: { type: Object, required: true },
    appointmentId: { type: String, required: true },
    initials: { type: String, required: true },
    initialsColor: { type: String, required: false },
    roomId: {type: [String, Number], required: true},
    title: { type: String, required: true },
    initialAllocatedTime: { type: Number, required: true },
    startedAt: {
      validator: prop => typeof prop === 'string' ||  prop === null,
      required: true
    },
    endedAt: {
      validator: prop => typeof prop === 'string' ||  prop === null,
      required: true
    },
    disabled: { type: Boolean, default: false }
  },
  data(): BaseData {
    return {
      checkIcon: mdiCheckboxMarkedCircleOutline,
      showDialog: false,
      interval: undefined,
      count: 0,
      interactWithBoard: false,
    }
  },
  async mounted() {
    this.interactWithBoard = await this.$store.dispatch('ableUserTo','interact with board');
  },
  computed: {
    ...mapState({
      timeNow: ({ time: x }) => x,
      openedTreatment: ({ openedTreatment }) => openedTreatment
    }),
    canInteractWithTreatment() {
      return !['completed',].includes(this.treatment.status)
    },
    bgColor(): string {
      if (!!this.endedAt || !this.startedAt) return 'whiteCard'
      if (this.isOverTime) return 'redCard'
      if (this.isOverTimeAlmost) return 'yellowCard'
      return 'greenCard'
    },
    treatmentState(): string {
      if (!!this.endedAt) return 'completed'
      if (!this.startedAt || this.isPaused) return 'default'
      if (this.isOverTime) return 'overtime'
      if (this.isOverTimeAlmost) return 'warning'
      return 'active'
    },
    isOngoing() { return !this.endedAt && !!this.startedAt },
    isOverTime() {
      return this.timeElapsed > this.timeAllocated
    },
    isOverTimeAlmost() {
      return this.timeElapsed > (this.timeAllocated - 180000)
    },
    isPaused(): boolean { return this.treatment.status === 'paused' },
    isProcessing(): boolean { return this.treatment.status === 'processing' },

    timeElapsedBefore(): number { return this.treatment.time_elapsed * 1000 },
    timeElapsed(): number {
      return this.timeElapsedBefore + (this.isPaused ? 0 : this.timeSinceResume)
    },
    timeSinceResume(): number {
      const timeResumed = this.timeResumed > 0 ? this.timeResumed : this.$dayjs(this.treatment.started_at).utc().valueOf()
      return this.timeNow.valueOf() - timeResumed
    },
    timeResumed(): number {
      return (!!this.startedAt && !this.isPaused && !!this.treatment.resumed_at)
          ? this.$dayjs(this.treatment.resumed_at).utc().valueOf()
          : 0
    },
    timeElapsedFormatted(): any {
      const t = this.$dayjs.duration(this.timeElapsed).asMilliseconds();
      return this.$dayjs.utc(t).format(t >= 3600000 ? 'hh:mm' : 'mm:ss')
    },
    timeAllocated(): any {
      return this.initialAllocatedTime
    },
    timeAllocatedFormatted(): any {
      const t = this.$dayjs.duration(this.timeAllocated).asMilliseconds();

      return this.$dayjs.utc(t).format(t >= 3600000 ? 'hh:mm' : 'mm:ss')
    },
    initialsBackgroundColor() { return this.initialsColor || randomColor() },
    playIcon() { return this.isPaused ? mdiMotionPlayOutline : mdiPlay },
    filter() {
      return this.$store.state.filter;
    },
    isDisabled() {

      if (!!this.filter) {
        return this.treatment.doctor.uuid !== this.filter
      }

      return this.appointment.treatments
          .filter(({ uuid }) => uuid !== this.treatment.uuid)
          .some(({ started_at: a, ended_at: b }) => (!!a && !b))
    }
  },
  watch: {
    timeNow() { this.emitTime() },
    isOverTime: "playWarning",
  },
  methods: {
    ...mapActions([
      'LOAD_DASHBOARD',
      'UPDATE_APPOINTMENT_STATUS',
      'HANDLE_RESET_TREATMENT',
      'HANDLE_PLAY_TREATMENT',
      'HANDLE_PAUSE_TREATMENT',
      'HANDLE_COMPLETE_TREATMENT',
      'HANDLE_UPDATE_ALLOCATED_TIME_TREATMENT'
    ]),
    playWarning(){
      let treatmentKey = null
      this.appointment.treatments.map((t,key) => {
        if (t.uuid === this.treatment.uuid){
          treatmentKey = key
        }
      });
      let isPlayedWarning = this.appointment.treatments[treatmentKey].playedWarning
      const activeStatus = [ 'processing', 'pending' ]

      if (this.isOverTime && !isPlayedWarning && activeStatus.includes(this.treatment.status)){
        let dangerAudio = new Audio(require('@/assets/audio/danger.mp3'));
        dangerAudio.play()
        this.appointment.treatments[treatmentKey].playedWarning = true
      }
    },

    async startTreatment(event?: any): Promise<void> {
      let condition = this.appointment.treatments
          .filter(({ uuid }) => uuid !== this.treatment.uuid)
          .some(({ started_at, ended_at }) => (!!started_at && !ended_at));

      if (!condition) {
        const {
          $store: { state: { auth: { user: { uuid: user }}}},
          appointmentId,
          id: treatment,
          roomId: room,
          startedAt
        } = this
        const params = { user }

        await Promise.all([
          this.HANDLE_PLAY_TREATMENT({
            appointmentId, params: {
            ...params,
            treatment,
            ...(!!startedAt ? {} : {
              room,
            })
            }
          })
        ])

      }

    },

    emitTime() {
      const time = this.timeElapsed
      this.$eventBus.$emit(`room-${this.roomId}-ticker`, { time })
    },

    openDialog() {
      store.commit('toggleTreatment', { openedTreatmentUUID: this.treatment.uuid });
    },

    closeDialog() {
      store.commit('toggleTreatment', { openedTreatmentUUID: null });
    },

    async handlePlay(): Promise<void> {
      if(!this.isDisabled) {
        await this.startTreatment()
      }
    },

    handlePause: async function(): Promise<void> {

      if (!this.canInteractWithTreatment || !this.isProcessing || this.isDisabled) {
        return null;
      }

      const {
        $store: {state: {auth: {user: {uuid: user}}}},
        appointmentId,
        id: treatment
      } = this;
      const params = {user};

      await this.HANDLE_PAUSE_TREATMENT({
        appointmentId, params: {
          ...params,
          time_elapsed: ((this.timeElapsed / 1000) ?? 0).toString(),
          treatment
        }
      });
    },

    async handleCompleteTreatment(): Promise<void> {
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointmentId,
        id: treatment
      } = this

      await this.HANDLE_COMPLETE_TREATMENT({ appointmentId, params: {
        time_elapsed: ((this.timeElapsed / 1000) ?? 0).toString(),
        treatment,
        user
      }})

      this.$emit('complete');
    },

    async handleResetTreatment(): Promise<void> {
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointmentId,
        id: treatment
      } = this

      await this.HANDLE_RESET_TREATMENT({ appointmentId, params: {
        treatment,
        user
      }})

      this.emitTime();
      this.$emit('reset');
    },

    async handleUpdatetimeAllocated(payload: number): Promise<void> {
       const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointmentId,
        id: treatment
      } = this

      const timeAllocated = this.timeAllocated + (payload * 1000);
      await this.HANDLE_UPDATE_ALLOCATED_TIME_TREATMENT({ appointmentId, params: {
        treatment,
        duration: (timeAllocated / 1000).toString()
      }})
    },

    start(): void {
      if (!this.interval) this.interval = setInterval(() => {
        this.count++;
        if (this.count > 15) this.openDialog();
      }, 30)
    },

    stop(): void {
      clearInterval(this.interval);
      this.interval = undefined;
      this.count = 0;
    }

  }
});
