<template>
  <div>
    <div class="py-4 px-4 pb-0 md:px-0 w-full lighten-1 flex justify-space-between mt-4">

      <!--  User Name  -->
      <v-text-field
          label="Name"
          v-model="profile.name"
          inline
          outlined
          :color="nameColor"
          :append-icon="appendIconName"
          :hint="profileNameHint"
          :persistent-hint="!!profileNameHint"
          class="profilePageElement"
          @focus="appendIconName = 'mdi-checkbox-marked-circle'"
          @blur="appendIconName = undefined"
      >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="updateNameEmail($event)"
                  style="display: inline-block; padding: 0; min-width: unset; height: unset"
              >
                <v-icon
                    color="#2F6B93"
                    v-if="appendIconName"
                >
                  mdi-checkbox-marked-circle</v-icon>
              </v-btn>
            </template>
            <span>Tap to accept</span>
          </v-tooltip>
        </template>
      </v-text-field>

      <!--  User Email  -->
      <v-text-field
          label="Email"
          class="profilePageElement"
          v-model="profile.email"
          inline
          outlined
          :color="emailColor"
          :hint="profileEmailHint"
          :persistent-hint="!!profileEmailHint"
          :append-icon="appendIconEmail"
          @focus="appendIconEmail = 'mdi-checkbox-marked-circle'"
          @blur="appendIconEmail = undefined"
      >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="updateNameEmail($event)"
                  style="display: inline-block; padding: 0; min-width: unset; height: unset"
              >
                <v-icon
                    color="#2F6B93"
                    v-if="appendIconEmail"
                >
                  mdi-checkbox-marked-circle</v-icon>
              </v-btn>
            </template>
            <span>Tap to accept</span>
          </v-tooltip>
        </template>
      </v-text-field>

    </div>
    <div class="py-4 px-4 md:px-0 w-full lighten-1 flex justify-space-between">

      <!--  User Password  -->
      <div class="profilePageElementContainer">
        <v-text-field
            label="Password"
            ref="profilePasswordInput"
            class="profilePasswordFields"
            :type="passwordFieldShow ? 'text' : 'password'"
            inline
            outlined
            :color="passwordColor"
            v-model="profilePassword"
            :append-icon="passwordFieldShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordFieldShow = !passwordFieldShow"
            @focus="appendIconPassword = 'mdi-eye'"
            :hint="passwordFieldHint"
            :persistent-hint="!!passwordFieldHint"
            @blur="appendIconPassword = undefined"
        ></v-text-field>

        <v-text-field
            v-if="profilePassword !== profilePasswordDefault"
            label="Confirm Password"
            ref="profilePasswordConfirmationInput"
            class="profilePasswordFields"
            :type="passwordFieldShow ? 'text' : 'password'"
            inline
            outlined
            :color="passwordConfirmationColor"
            v-model="profilePasswordConfirmation"
            :append-icon="passwordFieldShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordFieldShow = !passwordFieldShow"
            @focus="appendIconPassword = 'mdi-eye'"
            :hint="passwordConfirmationFieldHint"
            :persistent-hint="!!passwordConfirmationFieldHint"
            @blur="appendIconPassword = undefined"
        ></v-text-field>
      </div>

      <div class="profilePageElement changePasswordButton" v-if="profilePassword !== profilePasswordConfirmation">
        <span @click="$refs.profilePasswordInput.focus()">Change Password</span>
      </div>

      <div class="profilePageElement changePasswordButton"
           v-if="profilePassword === profilePasswordConfirmation">
        <v-btn
            v-on:click="showAddItem = true"
            color="#2F6B93"
            style="color: white"
            @click="updatePassword"
        >
          <div class="flex items-center">
            <span class="mr-1">Save Password</span>
            <v-icon>mdi-account-edit</v-icon>
          </div>
        </v-btn>
      </div>



    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      profile: {},
      appendIconName: undefined,
      appendIconEmail: undefined,
      passwordFieldShow: false,
      passwordFieldHint: 'At Least 6 symbols',
      passwordConfirmationFieldHint: undefined,
      profilePasswordDefault: '*********',
      profilePassword: '*********',
      profilePasswordConfirmation: undefined,
      profileNameHint: undefined,
      profileEmailHint: undefined,
      nameColor: '#056C97',
      emailColor: '#056C97',
      passwordColor: '#056C97',
      passwordConfirmationColor: '#056C97',
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      user: state => state.auth.user,
    }),
  },
  mounted() {
    this.profile = this.user
  },
  watch: {
    user() {
      this.profile = this.user
    }
  },
  methods: {
    async updatePassword() {

      if (this.profilePassword === this.profilePasswordDefault) {
        this.$refs.profilePasswordInput.focus()
        this.passwordFieldHint = "Edit password to make changes"
        this.passwordColor = 'red darken-1'
        return false
      }

      if (this.profilePassword.length < 6) {
        this.$refs.profilePasswordInput.focus()
        this.passwordFieldHint = "Password should contains at least 6 symbols!"
        this.passwordColor = 'red darken-1'
        return false
      }

      if (this.profilePassword !== this.profilePasswordConfirmation) {
        this.$refs.profilePasswordConfirmationInput.focus()
        this.passwordConfirmationFieldHint = "Password confirmation does not match"
        this.passwordConfirmationColor = 'red darken-1'

        return false
      }

      if (confirm('Are you sure to change password?')) {
        const payload = {
          userUUID: this.user.uuid,
          data: {
            password: this.profilePassword,
            password_confirmation: this.profilePasswordConfirmation,
          }
        };

        const success = await this.$store.dispatch('USER_PASSWORD_CHANGE', payload)

        if (success) {
          this.passwordFieldShow = false
          this.profilePasswordDefault = this.profilePassword
          this.profilePasswordConfirmation = ''
          this.$refs.profilePasswordInput.focus()
          this.passwordFieldHint = 'Password was changed'
          this.passwordColor = 'green darken-1'
        } else {
          this.$refs.profilePasswordInput.focus()
          this.passwordFieldHint = "Couldn\'t change password"
          this.passwordColor = 'red darken-1'
        }
      }
    },
    async updateNameEmail() {
      const payload = {
        userUUID: this.user.uuid,
        data: {
          name: this.profile.name,
          email: this.profile.email,
          role: this.profile.role,
        }
      }

      const success = await this.$store.dispatch('UPDATE_USER', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Profile info of ${this.profile.name} was changed successful.` });
      } else {
        this.userLocations = this.locations
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing profile info of ${this.profile.name}.` });
      }
    }
  }
}
</script>

<style scoped>
  .profilePageElement {
    max-width: 48%;
  }
  .profilePageElementContainer {
    width: 48%;
  }
  .profilePasswordFields {
    width: 100%;
  }
  .changePasswordButton {
    color: #056C97;
    width: 48%;
    line-height: 3em;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
