<template>
  <div>
    <v-dialog v-model="showDialog" max-width="50%" style="height: 500px; background-color: #FAFAFA;">
      <v-card class="p-4">
        <v-select
          v-if="dialogType === 'treatment'"
          v-model="newTreatment"
          hint="Select a treatment to add for this room"
          :items="filteredTreatments"
          item-text="name"
          item-value="uuid"
          label="Treatment"
          persistent-hint
          single-line
          append-outer-icon="mdi-check"
          @click:append-outer="addTreatmentToRoom"
        ></v-select>
        <div v-if="dialogType === 'color'" class="w-full flex flex-col">
          <div class="mb-4">
            Update the color associated with this room.
          </div>
          <v-color-picker
            v-model="roomColor"
            class="mx-auto mb-2"
            hide-mode-switch
            mode="hexa"
            :swatches="swatches"
            show-swatches
          ></v-color-picker>
          <v-btn
            class="self-end"
            color="#77ACFF"
            style="color: white;"
            @click="handleUpdateColor"
          >
            UPDATE
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-card flat tile>
      <v-card-text>
        <SettingsRoomCard
          v-for="(room, i) in rooms"
          :room="room"
          :room-color="room.color"
          :key="`room-${room.uuid}`"
          @add-treatment="handleShowDialog"
          @add-all-treatments="handleAddAllTreatmentsToRoom"
          @update-color="handleShowDialog"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import SettingsRoomCard from '@/components/cards/SettingsRoomCard.vue';

export default Vue.extend({
  name: 'SettingsDialogPractice',
  components: {
    SettingsRoomCard
  },
  props: {
    rooms: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      dialogType: null,
      newTreatment: '',
      updatingRoom: null,
      roomColor: null,
      roomName: null,
      swatches: [
        ['#56C2DE',
        '#5047A5'],
        ['#F29E41',
        '#21BA33'],
        ['#DE435B',
        '#1976A8'],
        ['#19A881',
        '#9719A8'],
        ['#FFA558',
        '#82C795']
      ],
    };
  },
  computed: {
    filteredTreatments() {
      if (!this.updatingRoom) return [];
      const room = this.rooms.find(d => d.uuid === this.updatingRoom);
      const roomTreatments = room.treatments.map(t => t.uuid);
      return this.treatments.filter(t => !roomTreatments.includes(t.uuid));
    },
    treatments() {
      return this.$store.state.treatments;
    }
  },
  methods: {
    handleShowDialog(payload) {
      this.showDialog = true;
      this.updatingRoom = payload.uuid;
      this.dialogType = payload.type;
      if (payload.color) {
        this.roomColor = payload.color?.hex ?? payload.color;
        this.roomName = payload.name
      }
    },
    async addTreatmentToRoom() {
      const payload = {
        roomId: this.updatingRoom,
        status: 'add',
        params: { treatment: this.newTreatment }
      };
      const success = await this.$store.dispatch('UPDATE_ROOM_TREATMENT', payload);
      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatment to room.' });
        await this.$store.dispatch('LOAD_ROOMS');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    async handleAddAllTreatmentsToRoom({ uuid }) {
      this.updatingRoom = uuid
      const successArr = await Promise.all(
        this.filteredTreatments.map(async x =>
          this.$store.dispatch('UPDATE_ROOM_TREATMENT', {
            roomId: uuid,
            status: 'add',
            params: { treatment: x.uuid }
          })
        )
      )

      console.log(successArr)

      if (!successArr.every(x => x)) {
        this.$eventBus.$emit('emit-error', { state: true })
      } else {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatments to room.' });
      }

      await this.$store.dispatch('LOAD_ROOMS');
    },
    async handleUpdateColor() {
      const payload = {
        roomId: this.updatingRoom,
        params: { color: this.roomColor, name: this.roomName }
      };

      const success = await this.$store.dispatch('UPDATE_ROOM_SETTINGS', payload);

      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Updated color.' });
        await this.$store.dispatch('LOAD_ROOMS');
        await this.$store.dispatch('LOAD_DASHBOARD');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    }
  }
});
</script>

<style scoped></style>
