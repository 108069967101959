import ApiService from '@/services/api.service';
import pusher from '@/services/pusher.service';
import tc from '@replygirl/tc';
import {constantCase} from 'change-case';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import equal from 'fast-deep-equal';
import Vue from 'vue';
import Vuex, {Store} from 'vuex';

import type {AuthState} from './modules/auth';
import {auth} from './modules/auth';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {StorageService} from '@/services/storage.service';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('US/Eastern');

Vue.use(Vuex);

declare interface ApiError {
  name: string;
  message: string;
  errorCode: number | null;
}

class ApiError extends Error {
  constructor(errorCode: number | null, message: string) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const throwApiError = (e: any) => {
  console.error(e);
  throw new ApiError(
    e?.response?.status ?? null,
    e?.response?.data?.message,
  );
};

export const callbacks = {
  appointments: {
    filter: {
      hasDoctor: (uuid?: string) => ({ doctor, doctors }) =>
        !uuid
        || doctor?.uuid === uuid
        || doctors?.map(({ doctor }) => doctor?.uuid).includes(uuid),
      isNotHidden: ({ doctors }) => {
        let display = false;

        if (doctors?.length === 0) { return false; }

        doctors?.map((t) => {
          // here we searching for elem not contains elem of array below
          if (!['No Show', 'Rescheduled', 'Cancelled'].includes(t.status)) {
            display = true;
          }
        });
        return display;
      },
      hasStartDate: ({ starts_at: x }) => !!x,
      isNotHere: ({ status: x }) =>
        !['processing', 'paused', 'waiting'].includes(x),
      isWaiting: ({ status: x }) => x === 'waiting',
      notCompleted: ({ status: x }) => x !== 'completed',
      startsToday: ({ starts_at: x }) => dayjs(x).isToday(),
      startsTomorrow: ({ starts_at: x }) => dayjs(x).isTomorrow(),
    },
    map: {
      offsetStartTime: (officeUuid: string) => (x: any) => ({
        ...x,
        ...(
          (
            !!x.starts_at
          )
            ? {
              starts_at: dayjs(x.starts_at)
                // .tz('US/Eastern')
                // .add(4, 'hour') // TEMP
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
            }
            : {}
        ),
        treatments: x.treatments?.map((y) => ({
          ...y,
          ...(y?.started_at
              ? {
                started_at: dayjs(y.started_at)
                  // .tz('US/Eastern')
                  // .add(4, 'hour') // TEMP
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'),
                playedNotify: y.time_elapsed >= (y.duration - 180),
                playedWarning: y.time_elapsed >= y.duration,
              }
              : {}
          ),
        })) ?? [],
        doctors: x.doctors?.map((y) => ({
          ...y,
          ...(y?.scheduled_at
                  ? {
                    scheduled_at: dayjs(y.scheduled_at)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss'),
                  }
                  : {}
          ),
        })) ?? [],
      }),
    },
    sort: {
      startsAt: {
        asc: ({ starts_at: a }, { starts_at: b }) => dayjs(a).diff(dayjs(b)),
      },
    },
  },
};

export interface State {
  administrators: any[];
  apiError: string;
  apiErrorCode: number;
  appointment: any | null;
  appointments: any[];
  openedTreatment: string | null;
  confirmation: {
    message: string
    uuid: string
    wasConfirmed: any | null,
  };
  confirmationMessage: string;
  confirmationStatus: boolean;
  confirmingItem: string;
  doctors: any[];
  newWindowOpen: boolean;
  errorState: boolean;
  history: any[];
  filter: string;
  lastAppointment: any | null;
  notes: any[];
  queueRefreshInterval: number | null;
  queueRefreshIntervalPeriod: number; // ms
  rooms: any[];
  selectedPatient: any | null;
  selectedTreatment: any | null;
  analyticInfo: any | null;
  analyticPatients: any | null;
  analyticDates: any | null;
  showAppointment: boolean;
  showConfirmation: boolean;
  stopLastAppointmentsOnMount: any[];
  time: Date | null;
  treatments: any[];
  virtualKeyboardValues: string;
  showVirtualKeyboard: boolean;
  virtualKeyboardRelatedId: string;
  users: any[];
  roles: any[];
  locationsAll: any[];
  practicesAll: any[];
  practiceCandidates: any[];
  appointmentHistory: any[];
}

export interface RootState extends State {
  auth: AuthState;
}

const initState = (x: State) => x as RootState;

export default new Store<RootState>({
  state: initState({
    administrators: [],
    apiError: '',
    apiErrorCode: 0,
    appointment: null,
    appointments: [],
    openedTreatment: null,
    confirmation: {
      message: '',
      uuid: '',
      wasConfirmed: null,
    },
    confirmationMessage: '',
    confirmationStatus: false,
    confirmingItem: '',
    doctors: [],
    errorState: false,
    history: [],
    filter: '',
    lastAppointment: null,
    notes: [],
    queueRefreshInterval: null,
    queueRefreshIntervalPeriod: 5000, // ms
    rooms: [],
    selectedPatient: null,
    selectedTreatment: null,
    analyticInfo: null,
    analyticPatients: null,
    analyticDates: null,
    showAppointment: false,
    showConfirmation: false,
    stopLastAppointmentsOnMount: [],
    time: null,
    treatments: [],
    virtualKeyboardValues: '',
    showVirtualKeyboard: false,
    virtualKeyboardRelatedId: '',
    users: [],
    roles: [],
    locationsAll: [],
    practicesAll: [],
    practiceCandidates: [],
    newWindowOpen: false,
    appointmentHistory: [],
  }),
  getters: {
    appointments: ({ appointments, filter: uuid }) => appointments
      .filter(callbacks.appointments.filter.hasDoctor(uuid))
      .filter(callbacks.appointments.filter.isNotHidden)
      .sort(callbacks.appointments.sort.startsAt.asc),
    appointmentsScheduled: (_, { appointments }) => appointments
      .filter(callbacks.appointments.filter.hasStartDate)
      .filter(callbacks.appointments.filter.notCompleted),
    queue: (_, { appointmentsScheduled }) => appointmentsScheduled
      .filter(callbacks.appointments.filter.isWaiting),
    queueUpcoming: (_, { appointmentsScheduled }) => appointmentsScheduled
      .filter(callbacks.appointments.filter.startsToday)
      .filter(callbacks.appointments.filter.isNotHere)
      .sort(callbacks.appointments.sort.startsAt.asc),
    queueTomorrow: (_, { appointmentsScheduled }) => appointmentsScheduled
      .filter(callbacks.appointments.filter.startsTomorrow)
      .filter(callbacks.appointments.filter.isNotHere),
    users: (_, { users }) => users,
  },
  mutations: {
    apiError(
      state,
      { errorCode, errorMessage }: { errorCode: number, errorMessage: string },
    ) {
      state.auth.authenticating = false;
      state.apiErrorCode = errorCode;
      state.apiError = errorMessage;
    },
    toggleTreatment(state, { openedTreatmentUUID }: { openedTreatmentUUID: string | null}) {
      state.openedTreatment = openedTreatmentUUID;
    },
    setUsers( state, { users }: { users: any[] }) {
      state.users = users;
    },
    addUsersList( state, { user }: { user: any } ) {
      state.users = [
        user, ...state.users,
      ];
    },
    updateUsersList( state, { user }: { user: any } ) {
      const users = [];
      state.users.map((eachUser) => {
        eachUser.uuid !== user.uuid ? users.push(eachUser) : users.push(user);
      });
      state.users = users;
    },
    removeUserFromUsersList( state, { userUUID }: { userUUID: string } ) {
      state.users = state.users.filter( (user) => user.uuid !== userUUID);
    },
    setRoles( state, { roles }: { roles: any[] }) {
      state.roles = roles;
    },
    addLocationToList(state, { location }: { location: any }) {
      state.locationsAll = [
        location, ...state.locationsAll,
      ];
    },
    setLocationsAll( state, { locationsAll }: { locationsAll: any[] }) {
      state.locationsAll = locationsAll;
    },
    setPracticesAll( state, { practicesAll }: { practicesAll: any[] }) {
      state.practicesAll = practicesAll;
    },
    setPracticeCandidates( state, { practiceCandidates }: { practiceCandidates: any[] }) {
      state.practiceCandidates = practiceCandidates;
    },
    updateLocationsList( state, { location }: { location: any } ) {
      const locations = [];
      state.locationsAll.map((eachLocation) => {
        eachLocation.uuid !== location.uuid ? locations.push(eachLocation) : locations.push(location);
      });
      state.locationsAll = locations;
    },
    removeLocationFromList( state, { locationUUID }: { locationUUID: string } ) {
      state.locationsAll = state.locationsAll.filter( (location) => location.uuid !== locationUUID);
    },
    updatePracticesList( state, { practice }: { practice: any } ) {
      const practices = [];
      state.practicesAll.map((eachPractice) => {
        eachPractice.uuid !== practice.uuid ? practices.push(eachPractice) : practices.push(practice);
      });
      state.practicesAll = practices;
    },
    addPracticeToList(state, { practice }: { practice: any }) {
      state.practicesAll = [
        practice, ...state.practicesAll,
      ];
    },
    removePracticeFromList( state, { practiceUUID }: { practiceUUID: string } ) {
      state.practicesAll = state.practicesAll.filter( (practice) => practice.uuid !== practiceUUID);
    },
    setInputFromKeyboard(state, { input }: { input: string }) {
      state.virtualKeyboardValues = input;
    },
    setAdministrators(state, { admins }: { admins: any[] }) {
      state.administrators = admins;
    },
    setAppointment(state, { appointment }: { appointment: any }) {
      state.appointment = appointment;
    },
    setAppointmentHistory(state, { appointmentHistory }: { appointmentHistory: any }) {
      state.appointmentHistory = appointmentHistory;
    },
    setAppointments(state, { appointments }: { appointments: any[] }) {
      state.appointments = appointments;
    },
    setConfirmation(state, { message, uuid, wasConfirmed }) {
      state.confirmation = Object.assign({}, state.confirmation, {
        message,
        uuid,
        wasConfirmed,
      });
    },
    setConfirmationWasConfirmed(state, wasConfirmed: boolean) {
      state.confirmation = Object.assign({}, state.confirmation, {
        wasConfirmed,
      });
    },
    setDoctors(state, { doctors }: { doctors: any[] }) {
      state.doctors = doctors;
    },
    setErrorState(state, { error }: { error: boolean }) {
      state.errorState = error;
    },
    setFilter(state, { filter }: { filter: string }) {
      state.filter = filter;
    },
    setHistory(state, { history }: { history: any[] }) {
      state.history = history;
    },
    setLastAppointment(state, { lastAppointment }: { lastAppointment: any }) {
      state.lastAppointment = lastAppointment;
    },
    setNotes(state, { notes }: { notes: any[] }) {
      state.notes = notes;
    },
    setQueueRefreshInterval(state, x: number | null) {
      state.queueRefreshInterval = x;
    },
    setRooms(state, { rooms }: { rooms: any[] }) {
      state.rooms = rooms;
    },
    setPatient(state, { patient }: { patient: any }) {
      state.selectedPatient = patient;
    },
    setTime(state, { time }: { time: Date }) {
      state.time = time;
    },
    setTreatment(state, { treatment }: { treatment: any }) {
      state.selectedTreatment = treatment;
    },
    setTreatments(state, { treatments }: { treatments: any[] }) {
      state.treatments = treatments;
    },
    setAnalyticInfo(state, { data }: { data: any[] }) {
      state.analyticInfo = data;
    },
    setAnalyticPatients(state, { data }: { data: any[] }) {
      state.analyticPatients = data;
    },
    setAnalyticDates(state, { data }: { data: any[] }) {
      state.analyticDates = data;
    },
    toggleAppointment(state, { bool }: { bool: boolean }) {
      state.showAppointment = bool;
    },
    toggleKeyboard(state, { bool }: {bool: boolean }) {
      state.showVirtualKeyboard = bool;
    },
    setVirtualKeyboardRelatedId(state, { idOfRelated }: {idOfRelated: string}) {
      state.virtualKeyboardRelatedId = idOfRelated;
    },
    setNewWindowOpen(state, { bool }: {bool: boolean}) {
      state.newWindowOpen = bool;
      console.log('state.newWindowOpen', state.newWindowOpen);
    },
    toggleConfirmationStatus(state, { bool }: { bool: boolean }) {
      state.confirmationStatus = bool;
    },
    updateAppointmentTreatments(state, payload: any) {
      const { room, appointment, treatments } = payload;
      // tslint:disable-next-line:max-line-length
      state.auth.dashboard.rooms.find((r) => r.uuid === room).appointments.find((a) => a.uuid === appointment).treatments = [...treatments];
    },
    updateHistory(state, { appointments }: { appointments: any[] }) {
      state.history = [
        ...state.history.filter((x) =>
          !appointments.find((y) =>
            (x.uuid === y.uuid) && !equal(x, y),
          ),
        ),
        ...appointments.filter((x) =>
          !state.history.find((y) =>
            (x.uuid === y.uuid) && equal(x, y),
          ),
        ),
      ];
    },
    updateStopLastAppointmentsOnMount(state, { uuid }: { uuid: string }) {
      console.log('updating stop last appointments on mount', uuid);
      state.stopLastAppointmentsOnMount.push(uuid);
    },
  },
  actions: {
    CLEAR_HISTORY({ commit }) { commit('setHistory', { history: [] }); },
    async GET_ALL_LOCATIONS({ commit }) {
      try {
        const { data: { locations, practices } } = await ApiService.customRequest({
          method: 'get',
          url: `locations/all`,
        });
        commit('setLocationsAll', { locationsAll: locations });
        commit('setPracticesAll', { practicesAll: practices });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_LOCATION({ commit, dispatch }, payload) {
      try {
        const { locationUUID, changed, data } = payload;
        const { data: { location } } = await ApiService.customRequest({
          method: 'post',
          url: `locations/${locationUUID}/update`,
          data,
        });
        if (changed === 'office') {
          dispatch('GET_ALL_LOCATIONS');
        } else if (changed === 'info') {
          commit('updateLocationsList', { location });
        }
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOCATION_ACTIVITY_UPDATE({ commit, dispatch }, payload) {
      try {
        const { locationUUID, action } = payload;
        const { data: { location } } = await ApiService.customRequest({
          method: 'post',
          url: `locations/${locationUUID}/${action}`,
        });
        commit('updateLocationsList', { location });
        dispatch('LOAD_USER', { userId: StorageService.getUserId() });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOCATION_CREATE({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { location } } = await ApiService.customRequest({
          method: 'post',
          url: `locations/add`,
          data,
        });
        commit('addLocationToList', { location });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOCATION_DELETE({ commit }, payload) {
      try {
        const { locationUUID } = payload;
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `locations/${locationUUID}/delete`,
        });
        commit('removeLocationFromList', { locationUUID });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_ALL_PRACTICES({ commit }) {
      try {
        const { data: { practices }} = await ApiService.customRequest({
          method: 'get',
          url: `practices/all`,
        });
        commit('setPracticesAll', { practicesAll: practices });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_ALL_PRACTICES_CANDIDATES({ commit }) {
      try {
        const { data: { practices }} = await ApiService.customRequest({
          method: 'get',
          url: `practices/candidates`,
        });
        commit('setPracticeCandidates', { practiceCandidates: practices });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_PRACTICE({ commit, dispatch }, payload) {
      try {
        const { practiceUUID, data } = payload;
        const { data: { practice } } = await ApiService.customRequest({
          method: 'post',
          url: `practices/${practiceUUID}/update`,
          data,
        });

        commit('updatePracticesList', { practice });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async PRACTICE_ACTIVITY_UPDATE({ commit }, payload) {
      try {
        const { practiceUUID, action } = payload;
        const { data: { practice } } = await ApiService.customRequest({
          method: 'post',
          url: `practices/${practiceUUID}/${action}`,
        });
        commit('updatePracticesList', { practice });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async PRACTICE_CREATE({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { practice } } = await ApiService.customRequest({
          method: 'post',
          url: `practices/add`,
          data,
        });
        commit('addPracticeToList', { practice });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async PRACTICE_DELETE({ commit }, payload) {
      try {
        const { practiceUUID } = payload;
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `practices/${practiceUUID}/delete`,
        });
        commit('removePracticeFromList', { practiceUUID });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async ALL_USERS({ commit }) {
      try {
        const { data: { users } } = await ApiService.customRequest({
          method: 'get',
          url: `users/all`,
        });
        commit('setUsers', { users });
        return users;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_CREATE({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/add`,
          data,
        });
        commit('addUsersList', { user });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_USER({ commit }, payload) {
      try {
        const { userUUID, data } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/update`,
          data,
        });
        commit('updateUsersList', { user });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_PASSWORD_CHANGE({ commit }, payload) {
      try {
        const { userUUID, data } = payload;
        const { data: info } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/password`,
          data,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_ACTIVITY_UPDATE({ commit }, payload) {
      try {
        const { userUUID, action } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/${action}`,
        });
        commit('updateUsersList', { user });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_DELETE({ commit }, payload) {
      try {
        const { userUUID } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/delete`,
        });
        commit('removeUserFromUsersList', { userUUID });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_NOTES({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `notes/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setNotes', { notes: data.notes });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_PATIENT_NOTES({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `patients/${state.appointment.patient.uuid}`,
        });
        return data;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_PATIENT_APPOINTMENTS({ commit, state }) {
      try {
        const officeUUID = state.auth.office;
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `patients/${state.appointment.patient.uuid}/appointments/${officeUUID}`,
        });
        commit('setAppointmentHistory', {appointmentHistory: data.patient.appointments});
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_TREATMENTS({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `treatments/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setTreatments', { treatments: data.treatments });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_DOCTORS({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `doctors/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setDoctors', { doctors: data.doctor });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async GET_ANALYTIC_INFO({ dispatch, commit, state }, payload: any) {
      const { params } = payload;
      try {

        const {data} = await ApiService.customRequest({
          method: 'get',
          url: `analytics/appointmentsAverageByPeriod`,
          params: {
            ...params,
            office: state.auth.office,
          },
        });
        commit('setAnalyticInfo', { data });
        return true;

      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async GET_ANALYTIC_PATIENTS({ dispatch, commit, state }, payload: any) {
      const { params } = payload;
      try {

        const {data} = await ApiService.customRequest({
          method: 'get',
          url: `analytics/appointmentPatientList`,
          params: {
            ...params,
            office: state.auth.office,
          },
        });
        commit('setAnalyticPatients', { data });
        return true;

      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async GET_APPT_START_END_DATES({ dispatch, commit, state }, payload: any) {
      const { params } = payload;
      try {

        const {data} = await ApiService.customRequest({
          method: 'get',
          url: `analytics/getFirstAndLastApptDates`,
          params: {
            office: state.auth.office,
          },
        });
        commit('setAnalyticDates', { data });
        return true;

      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async LOAD_ROOMS({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `rooms/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setRooms', { rooms: data.rooms });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_APPOINTMENT(
      {
        commit,
        state: {
          appointment: selectedAppointment,
          appointments,
          auth: {
            office,
            user: { uuid: user },
          },
        },
      },
      { appointmentId }: any,
    ) {

      return tc(async () => {
        const { data: { appointment } } = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${appointmentId}`,
          params: { user },
        });
        const match = (doesMatch = true) =>
          (x: any) => doesMatch === (x?.uuid === appointment.uuid);

        if (match()(selectedAppointment)) { commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        }

        if (!equal(appointment, appointments.find(match()) ?? {})) {
          commit('setAppointments', ({
            appointments: [
              ...appointments.filter(match(false)),
              callbacks.appointments.map.offsetStartTime(office)(appointment),
            ],
          }));
        }

        // commit('setTreatmentAfterChange', { data });
        // commit('setAppointment', {
        //     appointment:
        //       callbacks.appointments.map.offsetStartTime(office)(data.appointment),
        // });

        return true;
      }, throwApiError);
    },
    async LOAD_LAST_APPOINTMENT({ commit, state }, payload: any ) {
      const { uuid } = payload;
      try {
        const getPatientData = await ApiService.customRequest({
          method: 'get',
          url: `patients/${uuid}`,
          params: { user: state.auth.user.uuid },
        });
        const lastAppointmentUUID = getPatientData.data.patient.last_appointment.uuid;
        try {
          const getLastAppointmentData = await ApiService.customRequest({
            method: 'get',
            url: `appointments/${lastAppointmentUUID}`,
            params: { user: state.auth.user.uuid },
          });
          const lastAppointment = getLastAppointmentData.data.appointment;
          commit('setLastAppointment', { lastAppointment });
          return true;
        } catch (err) {
          throwApiError(err);
          return false;
        }
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async ADD_APPOINTMENT_TREATMENT(
      {
        commit,
        dispatch,
        state: {
          appointment: selectedAppointment,
          appointments,
          auth: {
            office,
            user: { uuid: user },
          },
        },
      },
      payload: any,
    ) {
      const { params, appointmentId } = payload;
      try {
        const { data: { appointment }} = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/add`,
          params,
        });
        const match = (doesMatch = true) =>
          (x: any) => doesMatch === (x?.uuid === appointment.uuid);
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        if (!equal(appointment, appointments.find(match()) ?? {})) {
          commit('setAppointments', ({
            appointments: [
              ...appointments.filter(match(false)),
              callbacks.appointments.map.offsetStartTime(office)(appointment),
            ],
          }));
        }
        commit('setTreatmentAfterChange', { data: { appointment } });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_APPT_TREATMENT_ORDER(
      {
        commit,
        dispatch,
        state: {
          auth: {
            office,
          },
        },
      },
      payload: any,
    ) {
      const { params, appointmentId } = payload;

      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/updateOrder`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_APPOINTMENT_TREATMENT(
      {
        commit,
        dispatch,
        state: {
          auth: {
            office,
          },
        },
      },
      payload: any,
    ) {
      const { params, appointmentId } = payload;
      try {
        const { data: { appointment }} = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/delete`,
          params,
        });
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_APPOINTMENT_STATUS(
      {
        commit,
        dispatch,
        state: {
          auth: {
            office,
          },
        },
      },
      payload: any,
    ) {
      const { params, appointmentId } = payload;
      try {
        const { data: { appointment }} = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/status`,
          params,
        });
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_PLAY_TREATMENT({ dispatch, commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/play`,
          params,
        });
        console.log({ params, data });
        commit('setTreatmentAfterChange', { data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        // console.log();
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_PAUSE_TREATMENT({ dispatch, commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/pause`,
          params,
        });
        console.log({ params, data });
        commit('setTreatmentAfterChange', { data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        // console.log();
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_COMPLETE_TREATMENT({ dispatch, commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/complete`,
          params,
        });
        console.log({ params, data });
        commit('setTreatmentAfterChange', { data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        // console.log();
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_RESET_TREATMENT({ dispatch, commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/reset`,
          params,
        });
        console.log({ params, data });
        commit('setTreatmentAfterChange', { data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        // console.log();
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_UPDATE_ALLOCATED_TIME_TREATMENT({ dispatch, commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/updateAllocatedTime`,
          params,
        });
        console.log({ params, data });
        commit('setTreatmentAfterChange', { data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        // console.log();
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_OF_APPOINTMENT_TREATMENT( { dispatch, commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/updateDoctor`,
          params,
        });
        commit('setTreatmentAfterChange', { data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['appointments', 'dashboard']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_TREATMENT_NAME({ dispatch }, payload: any) {
      const { params, treatmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/${treatmentId}/update`,
          params,
        });
        console.log({ params, data }),
          dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async TOGGLE_TREATMENT({ dispatch }, payload: any) {
      const { status, treatmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/${treatmentId}/${status}`,
        });
        console.log({ data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_TREATMENT({ dispatch }, payload: any) {
      const { params, treatmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/${treatmentId}/delete`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_SETTINGS({ dispatch }, payload: any) {
      const { params, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/update`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_STATUS({ dispatch }, payload: any) {
      const { status, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/${status}`,
        });
        console.log({ data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_TREATMENT({ dispatch }, payload: any) {
      const { status, doctorId, params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/treatments/${status}`,
          params,
        });
        console.log({ data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_DOCTOR({ dispatch }, payload: any) {
      const { params, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/delete`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOM_SETTINGS({ dispatch }, payload: any) {
      const { params, roomId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/update`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['rooms']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOM_STATUS({ dispatch }, payload: any) {
      const { status, roomId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/${status}`,
        });
        console.log({ data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['rooms']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOM_TREATMENT({ dispatch }, payload: any) {
      const { status, roomId, params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/treatments/${status}`,
          params,
        });
        console.log({ data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['rooms']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_ROOM({ dispatch }, payload: any) {
      const { params, roomId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/delete`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['rooms']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_DOCTOR({ dispatch }, payload: any) {
      const { params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/add`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_ROOM({ dispatch }, payload: any) {
      const { params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/add`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['rooms']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_TREATMENT({ dispatch }, payload: any) {
      const { params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/add`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_NOTE({ dispatch }, { params }: any) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `notes/add`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['notes']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_NOTE({ dispatch }, { params }: any) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `notes/edit`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['notes']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_NOTE({ dispatch }, { params }: any) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `notes/delete`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['notes']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_APPOINTMENT_TREATMENTS({ dispatch }, payload: any) {
      const { params, appointmentUuid } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${appointmentUuid}/treatments/getAll`,
          params,
        });
        return data;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async selectAppointment({ commit }, { appointment }: { appointment: any }) {
      commit('setAppointment', { appointment });
      commit('toggleAppointment', { bool: true });
    },
    async resetAppointment({ commit }) {
      commit('setAppointment', { appointment: null });
      commit('toggleAppointment', { bool: false });
    },
    async toggleKeyboard({ commit, state }) {
      commit('toggleKeyboard', { bool: !state.showVirtualKeyboard});

      // clearing virtual
      if (!state.showVirtualKeyboard) {
        commit('setInputFromKeyboard', ({
          input: '',
        }));
      }
    },
    async setKeyboardInput({ commit, state }, { input }: { input: string }) {
      commit('setInputFromKeyboard', ({
        input,
      }));
    },
    async setVirtualKeyboardRelatedId({ commit, state }, { idOfRelated }: { idOfRelated: string }) {
      commit('setVirtualKeyboardRelatedId', ({
        idOfRelated,
      }));
    },
    async loadTime({ commit }) {
      commit('setTime', { time: dayjs().toDate() });
    },
    async selectTreatment({ commit }, { treatment }: { treatment: any }) {
      commit('setTreatment', { treatment });
    },

    // Appointments & queue
    async LOAD_APPOINTMENTS({
      commit,
      state: {
        auth: {
          office,
          user: { uuid: user },
        },
      },
    }) {
      return await tc(async () => {
        const {
          data: {
            appointments,
          },
        } = await ApiService.get(`appointments/all/${office}`, {
          params: {
            end_date: dayjs().add(2, 'day').format('YYYY-MM-DD'),
            start_date: dayjs().format('YYYY-MM-DD'),
            user,
          },
        });
        commit('setAppointments', ({
          appointments: appointments.map(
            callbacks.appointments.map.offsetStartTime(office),
          ),
        }));
      });
    },

    // Confirmation dialogs
    ASK_FOR_CONFIRMATION({ commit }, { message, uuid }) {
      commit('setConfirmation', { message, uuid, wasConfirmed: null });
    },
    SET_CONFIRMATION_WAS_CONFIRMED({ commit }, wasConfirmed: boolean) {
      commit('setConfirmationWasConfirmed', wasConfirmed);
    },
    RESET_CONFIRMATION({ commit }) {
      commit('setConfirmation', { message: '', uuid: '', wasConfirmed: null });
    },
    async REGISTER_OPEN_NEW_WINDOW({ commit }) {
      try {
        const rand = () => Math.random().toString(36).substr(2);
        const token = () => rand() + rand() + rand();

        window.name = token();
        const windowName = window.name;
        const params = { windowName };

        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `window/new`,
          params,
        });

        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    // Pusher
    async SUBSCRIBE_TO_CLIENT_RELOAD({ dispatch, commit }, { uuid }) {
      interface ReloadEventData { resources: string[]; }
      interface AppointmentEventData { appointment: { uuid: string }; }

      const userUuid = StorageService.getUserId();

      const onAppointmentChanged =
        ({ appointment: { uuid: appointmentId }}: AppointmentEventData) =>
          dispatch(`LOAD_APPOINTMENT`, { appointmentId });

      const onApptQueueUpdate = () => dispatch(`LOAD_APPOINTMENTS`);

      const userOpenNewTab = ({ windowName }) => {
        if (windowName !== window.name) {
          window.onbeforeunload = () => false;
          commit(`setNewWindowOpen`, { bool: true });
          dispatch(`UNSUBSCRIBE_ALL`);
        }
      };

      await tc( () => pusher.subscribe(`userUuid-${userUuid}`, {
        'App\\Events\\UserTabsEvent': userOpenNewTab,
      }), console.error);

      await tc(() => pusher.subscribe(`private-reload-${uuid}`, {
        'client-reload': ({ resources }: ReloadEventData) =>
          resources.forEach((r) => dispatch(`LOAD_${constantCase(r)}`)),
        'App\\Events\\ReloadEvent': ({ resources }: ReloadEventData) =>
            resources.forEach((r) => dispatch(`LOAD_${constantCase(r)}`)),
      }), console.error);

      await tc(() => pusher.subscribe(`office.${uuid}`, {
        'App\\Events\\AppointmentCreatedEvent': onAppointmentChanged,
        'App\\Events\\AppointmentUpdatedEvent': onAppointmentChanged,
        'App\\Events\\ApptTreatmentOrderUpdateEvent': onAppointmentChanged,
        'App\\Events\\AppointmentQueueUpdate': onApptQueueUpdate,
      }), console.error);
    },
    TRIGGER_CLIENT_RELOAD(
      { state: { auth: { office: uuid }}},
      resources: string[],
    ) {
      pusher.trigger(`private-reload-${uuid}`, 'client-reload', { resources });
    },
    UNSUBSCRIBE_ALL() {
      pusher.unsubscribeAll();
    },
  },
  modules: { auth },
});
