



























































import Vue from 'vue';
import {mapActions, mapState} from 'vuex';
import Chart from 'chart.js';
import ApiService from '@/services/api.service';

export default Vue.extend({
  name: 'BarChart',
  props: {
    filter: String,
    history: Array,
    period: Object,
  },
  data() {
    return {
      chart: null,
      ctx: null,
      start: null,
      end: null,
      filteredTreatments: [],
      filteredDoctors: []
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      treatments: state => state.treatments,
      // @ts-ignore
      doctors: state => state.doctors,
    }),
  },
  watch: {
    period: 'updateChart',
    filteredDoctors: 'updateChart',
    filteredTreatments: 'updateChart'
  },
  mounted() {
    this.initiateChart();
  },
  beforeDestroy() {
    this.chart.destroy();
  },
  methods: {
    ...mapActions([
      'GET_ANALYTIC_INFO',
    ]),
    setDateRange() {
      const filter = this.filter === 'all' ? 'month' : this.filter;
      this.start = this.period;
      this.end = filter !== 'week' ? this.$dayjs(this.start).endOf(filter) : this.$dayjs(this.start).weekday(7).endOf('day');
    },
    async initiateChart() {
      this.setDateRange();
      this.ctx = document.getElementById('analytics-chart');
      const data = await this.chartDatasets();
      const options = this.chartOptions();
      console.log(data);
      this.chart = new Chart(this.ctx, {
        type: 'bar',
        data,
        options
      })
    },
    updateChart() {
      this.chart.destroy();
      this.initiateChart();
    },
    async chartDatasets() {

      await Promise.all([
        this.GET_ANALYTIC_INFO({ params: {
            filter: this.filter,
            start: this.$dayjs(this.start).format('YYYY-MM-DDTHH:mm:ss'),
            end: this.$dayjs(this.end).format('YYYY-MM-DDTHH:mm:ss'),
            doctors: this.filteredDoctors,
            treatments:  this.filteredTreatments
          }})
      ]);

      const analyticInfo = this.$store.state.analyticInfo;
      const waitData = analyticInfo.analytic.waitAvg;
      const apptData = analyticInfo.analytic.apptAvg;
      const treatmentData = analyticInfo.analytic.treatmentAvg;
      const labels = analyticInfo.rangeDates;

      const dataset = [
        {
          label: 'Avg Wait',
          backgroundColor: '#E83123',
          borderColor: '#E83123',
          borderWidth: '1',
          data: waitData,
        },
        {
          label: 'Avg Appt',
          backgroundColor: '#56D9FE',
          borderColor: '#56D9FE',
          borderWidth: '1',
          data: apptData,
        },
        {
          label: 'Avg Treatment',
          backgroundColor: '#5FE3A1',
          borderColor: '#5FE3A1',
          borderWidth: '1',
          data: treatmentData,
        }
      ];

      return {
        labels: labels,
        datasets: dataset,
      }
    },
    chartOptions() {
      return {
        barValueSpacing: 20,
        // legends: {
        //   align: 'left'
        // },
        layout: {
          padding: {
              left: 20,
              right: 20,
              top: 80,
              bottom: 20
          }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                      const y = (value).toLocaleString('en') ;
                      return y + 'min';
                    }
                }
            }]
        }
      }
    },
  }
});
