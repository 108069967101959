<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <SettingsTreatmentCard
          v-for="x in treatments"
          :treatment="x"
          :key="`treatment-${x.uuid}`"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import SettingsTreatmentCard from '@/components/cards/SettingsTreatmentCard.vue';

export default Vue.extend({
  name: 'SettingsDialogTreatments',
  components: { SettingsTreatmentCard },
  props: {
    treatments: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showAddTime: false,
      newTime: '',
      currentTreatmentIndex: 0
    };
  },
  methods: {
    removeTreatment(i) {
      this.treatments.splice(i, 1);
    },
    removeTime(treatmentIndex, timeIndex) {
      this.treatments[treatmentIndex].allocatedTimes.splice(timeIndex, 1);
    },
    showAddTimeDialog(treatmentIndex) {
      this.showAddTime = true;
      this.currentTreatmentIndex = treatmentIndex;
    },
    addTime() {
      this.treatments[this.currentTreatmentIndex].allocatedTimes.push(
        this.newTime
      );
      this.showAddTime = false;
      this.newTime = '';
    }
  }
});
</script>

<style scoped></style>
