


















































































































































































































import sum from "hash-sum";
import Vue from 'vue';
import {mapActions, mapState} from 'vuex';
import draggable from 'vuedraggable';
import Avatar from '@/components/basic/Avatar.vue';
import QueueCard from '@/components/cards/QueueCard.vue';

export default Vue.extend({
  name: 'WaitingRoom',
  components: { Avatar, draggable, QueueCard },
  props: {
    queue: {
      type: Array,
      required: true,
    },
    queueUpcoming: {
      type: Array,
      required: true,
    },
    queueTomorrow: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      componentKey: 0,
      dragClass: 'ghost',
      isDraggingQueuePatient: false,
      isDraggingRoomPatient: false,
      isDraggingRoomPatientIndex: 0,
      filteredAppointments: [],
      group: {
        name: 'foo',
        pull: true,
        put(to, from) {
          console.log({ to, from })
          // Return true to allow, return false to disallow
          return true;
        }
      },
      rooms: [],
      roomSlots:
        Array(20).fill(null).map(() => ({ uuid: sum(Math.random()) })) as any,
      roomSlotsDragIndexIsValid: false,
      interactWithBoard: false,
    }
  },
  async mounted() {
    this.interactWithBoard = await this.$store.dispatch('ableUserTo','interact with board');
    this.setRooms();
  },
  computed: {
    ...mapState({
      roomsInStore: (state: any) => state.auth.dashboard.rooms,
      choosenAppt: (state: any) => state.appointment,
      time: (state: any) => state.time,
    }),
    filter() {
      return this.$store.state.filter;
    },
    filteredQueue() {
      if (!this.filter) return this.queue;
      return this.queue.filter(item => {
        const doctors = item.treatments.map(t => t.doctor.uuid);
        return doctors.includes(this.filter);
      });
    },
    filteredRooms() {
      this.filterRooms();
      return [...this.rooms];
    },
    filteredIds() {
      return this.filteredRooms.map(r => r.name);
    },
    ids() {
      return this.rooms.map(r => r.name);
    },
    isDraggingQueuePatientGhostClass() {
      return `room-slots__slot--${
        this.roomSlotsDragIndexIsValid ? '' : 'in'
      }valid`
    }
  },
  watch: {
    roomsInStore: {
      deep: true,
      handler() {
        this.setRooms();
      }
    },
    rooms() { this.resetRoomSlots() }
  },
  methods: {
    ...mapActions(['selectAppointment']),
    activeAppointment(room: any) {
      return room?.appointments?.find(({ status: x }) =>
        ['processing', 'paused'].includes(x)
      ) ?? false
    },
    isTreatmentsOrdered(appointment) {
      let hasOrderVal = false;

      if (appointment?.treatments.length > 0) {
        appointment?.treatments.map(({order_val: orderVal}) => hasOrderVal = !!orderVal );
      }
      return hasOrderVal;
    },
    filterRooms() {
      const filtered = (t) => {
        if (t.doctor) {
          if (t.doctor.uuid === this.filter) {
            return true;
          }
        }
        return false;
      }

      let filteredAppointments = [];
      this.rooms.forEach(r => {
        let appt = this.activeAppointment(r);
        if (appt) {
          filteredAppointments.push(r.uuid);
        }
      })
      this.filteredAppointments = filteredAppointments;
    },
    getDoctorName(item) {
      return item.treatments?.[0]?.doctor?.name ?? item.doctor?.name ?? 'X X'
    },
    onQueuePatientDragMoved({
      draggedContext: { futureIndex: i },
      to: { id }
    }) {
      this.roomSlotsDragIndexIsValid =
        id==='room-slots' && !this.activeAppointment(this.rooms[i])
    },
    onRoomPatientDragStart() { this.isDraggingRoomPatient = true },
    getOrder(treatments){
      const treatmentsUuidAr = [];

      if (treatments.length === 0) { return treatmentsUuidAr }

      treatments.map((t) => {
        treatmentsUuidAr.push(t.uuid)
      })

      return treatmentsUuidAr;
    },
    async onRoomPatientDragEnd(event) {
      const { newIndex, oldIndex } = event;
      this.isDraggingRoomPatientIndex = this.oldIndex;
      this.isDraggingRoomPatient = false;
      const unchanged = this.$_.isEqual(this.roomsInStore, this.filteredRooms);
      const disabled = !!this.rooms[newIndex].appointments.find(
        ({ status: x }) => ['processing', 'paused'].includes(x)
      );
      if (unchanged || disabled) return;
      let newRoomIndex;
      if (disabled) {
        event.preventDefault();
        newRoomIndex = oldIndex;
      } else {
        newRoomIndex = newIndex;
      }

      const appt = this.roomsInStore[oldIndex].appointments.find(({ status: x }) => ['processing', 'paused'].includes(x));
      const apptUuid = appt.uuid;
      const newRoomUuid = this.roomsInStore[newRoomIndex].uuid;
      const payload = {
        appointmentId: apptUuid,
        params: {
          user: this.$store.state.auth.user.uuid,
          status: appt.status,
          room: newRoomUuid,
        }
      };
      const success = await this.$store.dispatch('UPDATE_APPOINTMENT_STATUS', payload);
      if (!success) {
        this.setRooms();
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
      await this.$store.dispatch('LOAD_DASHBOARD');
    },
    async onRoomPatientMoved(event) {
      if (this.activeAppointment(this.rooms[event.oldIndex])) return false
      this.dragClass = 'ghost';
      const { element, futureIndex, index } = event.draggedContext;
      // const disabled = !!this.rooms[futureIndex].appointments.find(({ status: x }) => ['processing', 'paused'].includes(x));
      // if (disabled) {
      //   this.dragClass = 'ghost--red';
      // }
    },
    async onRoomSlotsValueAdded({
      newIndex: roomsIndex,
      oldIndex: filteredQueueIndex,
      to: { id: dropZoneId }
    }) {
      if (dropZoneId === 'room-slots' && this.roomSlotsDragIndexIsValid)
        await this.startAppointment(
          this.filteredQueue[filteredQueueIndex].uuid,
          roomsIndex
        )
      this.resetRoomSlots()
    },
    patientName(name) { return name.replace('Dr. ', '') },
    resetRoomSlots() {
      if (!!this.rooms && this.rooms.length > 0) {
        this.roomSlots = Array(this.rooms.length - 1).fill(null).map(() => ({
          uuid: sum(Math.random())
        }))
      } else {
        this.roomSlots = Array(20).fill(null).map(() => ({ uuid: sum(Math.random()) }))
      }
    },
    roomIsOccupied(i) {
      return this.rooms[i]?.appointments?.find(({ status: x }) =>
        ['processing', 'paused'].includes(x)
      ) ?? false
    },
    setRooms() { this.rooms = [...this.roomsInStore] },
    async startAppointment(appointmentId, roomIndex) {
      const { dispatch } = this.$store
      const appt = this.queue.find((appointment) => appointment.uuid === appointmentId);

      if (!!appt && appt.treatments.length > 0 && !this.isTreatmentsOrdered(appt)) {
        const treatmentList = this.getOrder(appt.treatments);
        await dispatch('UPDATE_APPT_TREATMENT_ORDER', {
          appointmentId: appointmentId,
          params: {
            treatmentsUuidAr: treatmentList
          }
        });
      }

      await dispatch('UPDATE_APPOINTMENT_STATUS', {
        appointmentId,
        params: {
          room: this.rooms[roomIndex].uuid,
          status: 'new',
          user: this.$store.state.auth.user.uuid,
        }
      });

      // const appointment = this.$store.getters.queue.find(({ uuid: x }) => x === appointmentId;
      console.log(this.choosenAppt);

      await dispatch('selectAppointment', {
        appointment: this.choosenAppt
      });

      await Promise.all([
        this.$store.dispatch('LOAD_DASHBOARD'),
        this.$store.dispatch('LOAD_APPOINTMENTS')
      ])
    },
    openAppointmentDialog(appointment) {
      const payload = {
        appointment
      }
      this.selectAppointment(payload)
    },
  }
})
