var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full gray lighten-1 px-0",staticStyle:{"height":"475px","overflow-y":"scroll"}},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},_vm._l((_vm.list),function(appointment,i){return _c('v-expansion-panel',{key:("patient-list-item-" + (appointment.uuid)),class:['panel-bg', {'panel-border': i !== _vm.list.length - 1}]},[_c('v-expansion-panel-header',{on:{"click":function($event){return _vm.getOpenedAppointmentTreatments(appointment.uuid)}}},[_c('div',{staticClass:"flex items-center"},[_c('PatientListItem',{staticStyle:{"flex-grow":"0","width":"260px","max-width":"260px","margin-right":"8px"},attrs:{"image-url":appointment.patient_img,"initialsName":appointment.doctor_name,"initialsColor":appointment.doctor_color,"title":appointment.patient_name,"timestamp":appointment.starts_at,"no-padding":""}}),_c('div',{staticClass:"flex-col",staticStyle:{"flex-grow":"0","width":"138px","max-width":"138px","margin-right":"8px"}},[_c('span',{staticClass:"text-caption font-semibold gray--text mb-1"},[_vm._v(" Time in appointment ")]),_c('span',{staticClass:"block text-body-1",domProps:{"textContent":_vm._s(_vm.formatTime(appointment.totalTime))}})]),_c('div',{staticClass:"flex-col text-body-1",staticStyle:{"flex-grow":"0","width":"188px","max-width":"188px"}},[_c('span',{staticClass:"text-caption font-semibold gray--text mb-1",domProps:{"textContent":_vm._s(
                appointment.longestTreatmentTime > 0
                  ? 'Longest treatment'
                  : 'No treatments'
              )}},[_vm._v(" Longest treatment ")]),_c('span',{staticClass:"block text-body-1",domProps:{"innerHTML":_vm._s(
                appointment.longestTreatmentTime > 0
                  ? _vm.formatTime(appointment.longestTreatmentTime)
                  : '&nbsp;'
              )}})])],1)]),_c('v-expansion-panel-content',[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex flex-col text-caption",staticStyle:{"flex-grow":"0","margin-left":"268px","max-width":"200px","margin-right":"8px"}},[(appointment.checkedin_at)?_c('div',{staticClass:"flex items-center mb-1"},[_c('span',{staticClass:"gray--text text--lighten-3"},[_vm._v(" Checked in ")]),_c('span',{staticClass:"gray--text ml-1"},[_vm._v(" "+_vm._s(_vm.$dayjs.utc(appointment.checkedin_at).tz().format('hh:mma'))+" ")])]):_vm._e(),(appointment.arrived_at)?_c('div',{staticClass:"flex items-center mb-1"},[_c('span',{staticClass:"gray--text text--lighten-3"},[_vm._v(" Appointment started ")]),_c('span',{staticClass:"gray--text ml-1"},[_vm._v(" "+_vm._s(_vm.$dayjs.utc(appointment.arrived_at).tz().format('hh:mma'))+" ")])]):_vm._e(),(appointment.ended_at)?_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"gray--text text--lighten-3"},[_vm._v(" Appointment ended ")]),_c('span',{staticClass:"gray--text ml-1"},[_vm._v(" "+_vm._s(_vm.$dayjs.utc(appointment.ended_at).tz().format('hh:mma'))+" ")])]):_vm._e(),_c('div',{staticClass:"flex flex-column",staticStyle:{"margin":"30px 0 10px"}},[_c('span',{staticClass:"gray--text font-weight-bold"},[_vm._v(" Treatments ")])]),(_vm.selectedAppointmentTreatments !== null)?_c('div',{staticClass:"flex flex-column"},_vm._l((_vm.selectedAppointmentTreatments),function(treatment,i){return _c('div',{key:("appointment-" + i + "-" + (treatment.treatment ? treatment.treatment.uuid : treatment.uuid))},[_c('TextCard',{staticClass:"list-group-item mb-2 cursor-pointer",attrs:{"unripple":"","text":treatment.treatment.name,"initials":_vm._f("initials")(treatment.doctor.name),"initialsColor":treatment.doctor.color,"duration":treatment.actualduration,"no-border":true}})],1)}),0):_vm._e()])])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }