


























































import { mdiStar } from '@mdi/js'
import randomColor from 'randomcolor'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

import Avatar from '@/components/basic/Avatar.vue'

export default Vue.extend({
  name: 'QueueCard',
  components: { Avatar },
  props: {
    uuid: { type: [String, Number], required: true },
    appointment: { type: Object, required: true },
    name: { type: String, required: true },
    imageUrl: { type: String, required: false },
    doctorInitials: { type: String, required: true },
    newPatient: { type: Boolean, default: false },
    appointmentTime: { type: [Date, String], required: true },
    arrivalTime: { type: [Date, String], required: false },
    treatments: { type: Array, required: true },
    allowMakeManual: { type: Boolean, default: false },
  },
  data() {
    return {
      iconPath: mdiStar,
      overHour: false,
      interval: undefined,
      count: 0,
      interactWithBoard: false,
    }
  },
  async mounted() {
    this.interactWithBoard = await this.$store.dispatch('ableUserTo','interact with board');
  },
  computed: {
    ...mapState(['appointments', 'time']),
    borderColor() {
      if (this.waitTimeMinutes < 5) return 'border--default'
      else if (this.waitTimeMinutes < 10) return 'border--orange'
      else if (this.waitTimeMinutes > 9) return 'border--red'
    },
    doctors() {
      const doctors = this.appointment.doctors.map(t => t.doctor);
      return this.$_.uniqBy(doctors, 'uuid');
    },
    isLate() {
      const a = this.$dayjs(this.appointmentTime);
      const b = this.$dayjs(this.arrivalTime);
      return b.isAfter(a);
    },
    isMoreThanTenMinLate() {
      if (!this.arrivalTime) return false
      const a = this.$dayjs(this.appointmentTime);
      const b = this.$dayjs(this.arrivalTime);
      const diff = b.diff(a, 'minute');
      return diff >= 10;
    },
    waitTime() {
      const arrival = this.$dayjs(this.arrivalTime);
      const ms = this.$dayjs(this.time).diff(arrival, 's') * 1000;
      const dur = this.$dayjs.duration(ms).asMilliseconds();
      const format = ms >= 3600000 ? 'hh:mm' : 'mm:ss';
      this.overHour = format === 'hh:mm'
      return this.$dayjs.utc(dur).format(format);
    },
    waitTimeMinutes() { return parseInt(this.waitTime.split(':')[0]) },
    waitTimeTextColor() {
      if (this.waitTimeMinutes < 5) return 'black'
      else if (this.waitTimeMinutes < 10) return 'orange'
      else if (this.waitTimeMinutes > 9) return '#E83123'
    },
  },
  methods: {
    ...mapActions([
      'selectAppointment',
      'UPDATE_APPOINTMENT_STATUS',
    ]),
    getInitials(x: any) {
      return this.$options.filters.initials(x?.name ?? 'X X')
    },
    getInitialsStyle(x: any) {
      const backgroundColor =
        x?.color ?? (this.getInitials(x) === 'XX'
          ? this.$vuetify.theme.themes.light.red
          : randomColor()
        )
      return {
        backgroundColor,
        color: this.$options.filters.foregroundColor(backgroundColor)
      }
    },
    patientName(name) {
      return name.slice(0,3) === 'Dr.' ? name.slice(4) : name
    },
    startAppointment() {
      this.selectAppointment({
        appointment: this.appointments.find(({ uuid }) => uuid === this.uuid)
      })
    },
    async setAppointmentAsManual() {
      console.log('MANUAL CHECK-IN ACTION - LONG PRESS')
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointment: {
          uuid: appointmentId
        }
      } = this
      const params = {
        status: 'manual',
        user
      }
      await this.UPDATE_APPOINTMENT_STATUS({ appointmentId, params })
    },
  }
})
