<template>
  <div>
    <v-dialog v-model="showDialog" max-width="500px" style="height: 500px; background-color: #FAFAFA;">
      <v-card class="p-4">
        <v-select
          v-if="dialogType === 'treatment'"
          v-model="newTreatment"
          hint="Select a treatment to add for this practitioner."
          :items="filteredTreatments"
          item-text="name"
          item-value="uuid"
          label="Treatment"
          persistent-hint
          single-line
          append-outer-icon="mdi-check"
          @click:append-outer="handleAddTreatment"
        ></v-select>
        <div v-if="dialogType === 'color'" class="w-full flex flex-col">
          <div class="mb-4">
            Update the color associated with this practitioner.
          </div>
          <v-color-picker
            v-model="doctorColor"
            class="mx-auto mb-2"
            hide-mode-switch
            mode="hexa"
            :swatches="swatches"
            show-swatches
          ></v-color-picker>
          <v-btn
            class="self-end"
            color="#77ACFF"
            style="color: white;"
            @click="handleUpdateColor"
          >
            UPDATE
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-card flat tile>
      <v-card-text>
        <SettingsDoctorCard
          v-for="(doctor, i) in doctors"
          :doctor="doctor"
          :doctor-color="doctor.color"
          :key="`doctor-${doctor.uuid}`"
          @add-treatment="handleShowDialog"
          @add-all-treatments="handleAddAllTreatments"
          @update-color="handleShowDialog"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import SettingsDoctorCard from '@/components/cards/SettingsDoctorCard.vue';

export default Vue.extend({
  name: 'SettingsDialogDoctors',
  components: {
    SettingsDoctorCard
  },
  props: {
    doctors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      newTreatment: '',
      doctorColor: undefined,
      doctorName: undefined,
      updatingDoctor: null,
      dialogType: null,
      swatches: [
        ['#56C2DE',
        '#5047A5'],
        ['#F29E41',
        '#21BA33'],
        ['#DE435B',
        '#1976A8'],
        ['#19A881',
        '#9719A8'],
        ['#FFA558',
        '#82C795']
      ],
    };
  },
  computed: {
    filteredTreatments() {
      if (!this.updatingDoctor) return [];
      const dr = this.doctors.find(d => d.uuid === this.updatingDoctor);
      const drTreatments = dr.treatments.map(t => t.uuid);
      return this.treatments.filter(t => !drTreatments.includes(t.uuid));
    },
    treatments() {
      return this.$store.state.treatments;
    }
  },
  methods: {
    handleShowDialog(payload) {
      this.showDialog = true;
      this.updatingDoctor = payload.uuid;
      this.dialogType = payload.type;
      this.doctorColor = payload.color?.hex ?? payload.color;
      this.doctorName = payload.name ?? 'No name';
    },
    async handleAddTreatment(type) {
      const payload = {
        doctorId: this.updatingDoctor,
        status: 'add',
        params: { treatment: this.newTreatment }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_TREATMENT', payload);

      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatment to practitioner.' });
        await this.$store.dispatch('LOAD_DOCTORS');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    async handleAddAllTreatments({ uuid }) {
      this.updatingDoctor = uuid
      const successArr = await Promise.all(
        this.filteredTreatments.map(async x =>
          this.$store.dispatch('UPDATE_DOCTOR_TREATMENT', {
            doctorId: uuid,
            status: 'add',
            params: { treatment: x.uuid }
          })
        )
      )

      if (!successArr.every(x => x)) {
        this.$eventBus.$emit('emit-error', { state: true })
      } else {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatments to practitioner.' });
      }

      await this.$store.dispatch('LOAD_DOCTORS');
    },
    async handleUpdateColor() {
      const payload = {
        doctorId: this.updatingDoctor,
        params: { color: this.doctorColor, name: this.doctorName }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_SETTINGS', payload);

      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Updated color.' });
        await this.$store.dispatch('LOAD_DOCTORS');
        await this.$store.dispatch('LOAD_DASHBOARD');
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    }
  }
});
</script>

<style scoped></style>
