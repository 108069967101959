<template>
  <v-card
      flat
      class="card w-full pt-1 pb-5"
  >
    <div class="flex justify-between">
      <v-text-field
          label="Name"
          ref="practiceName"
          class="practiceNameField"
          inline
          outlined
          :color="practiceNameFieldColor"
          v-model="practiceName"
          :hint="practiceNameFieldHint"
          hide-details="auto"
          :persistent-hint="!!practiceNameFieldHint"
          :append-icon="practiceNameAppendIcon"
          @focus="practiceNameAppendIcon = 'mdi-checkbox-marked-circle'"
          @blur="practiceNameAppendIcon = undefined"
          @click:append="changePracticeInfo('Practice name')"
      />
      <div class="w-1/12 flex justify-start">
        <v-switch
            v-model="practiceEnabled"
            color="#66AB99"
            class="switchElem"
            @change="togglePracticeEnabled"
        ></v-switch>

        <v-btn text class="mb-2 p-0 gray--text" @click="deletePractice">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="flex justify-start w-9/12 pa-3">
      <span class="text-gray text-xs pr-12">
        <span v-if="!!practiceDrChronoOfficeName">
          Original name (DrChrono): <b>{{ practiceDrChronoOfficeName }}</b>
        <br>
        </span>
        Last updated from Dr. Chrono on <b>{{ updatedAt }}</b>
      </span>
    </div>

  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SettingsPracticeCard",
  props: {
    uuid: String,
    name: String,
    enabled: { type: Boolean, default: false },
    updatedAt: String,
    externalId: Number,
    drchronoName: String,
  },
  data() {
    return {
      practiceName: '',
      practiceEnabled: false,
      practiceNameFieldColor: '#056C97',
      practiceNameAppendIcon: undefined,
      practiceNameFieldHint: undefined,
      practiceDrChronoOffice: undefined,
      practiceDrChronoOfficeName: undefined,
    }
  },
  mounted() {
    this.updateFromProps()
  },
  computed: {
    ...mapState({
      practicesAll: state => state.practicesAll,
    }),
  },
  watch: {
    practicesAll() {
      this.updateFromProps();
    },
  },
  methods: {
    updateFromProps() {
      this.practiceName = this.name
      this.practiceEnabled = this.enabled
      this.practiceDrChronoOffice = this.externalId
      this.practiceDrChronoOfficeName = this.drchronoName
    },
    async togglePracticeEnabled() {

      let action = '';

      if (this.practiceEnabled){
        action = 'enable'
      } else {
        action = 'disable'
      }

      const payload = {
        practiceUUID: this.uuid,
        action: action,
      }

      const success = await this.$store.dispatch('PRACTICE_ACTIVITY_UPDATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Practice ${this.name} ${action}d successful .` });
        this.updateFromProps()
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while ${action} practice ${this.name}.` });
        this.updateFromProps()
      }

    },
    async changePracticeInfo() {

      let payload = {}

      if (this.externalId) {
        payload = {
          practiceUUID: this.uuid,
          data: {
            name: this.practiceName,
          }
        }
      } else {
        const drChrono = this.practiceCandidates.filter( pC => pC.id === this.practiceDrChronoOffice)[0]
        console.log('drChrono - ', drChrono.name)

        payload = {
          practiceUUID: this.uuid,
          data: {
            name: this.practiceName,
          }
        }
      }

      const success = await this.$store.dispatch('UPDATE_PRACTICE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Practice name of ${this.practiceName} was changed successful.` });
        this.updateFromProps()
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing Practice name of ${this.name}.` });
      }
    },
    async deletePractice() {
      if (confirm(`Are you sure to delete ${this.practiceName}?`)) {
        const payload = { practiceUUID: this.uuid }
        const success = await this.$store.dispatch('PRACTICE_DELETE', payload)

        if (success) {
          this.$eventBus.$emit('emit-success', { state: true, text: `${this.name} REMOVED! .` });
        } else {
          this.$eventBus.$emit('emit-error',
              { state: true, text: `Something went wrong while REMOVING ${this.name}.` });
        }
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
.card {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 2em;
}

.practiceNameField {
  width: 30%;
  margin-right: 10px;
}

.switchElem {
  margin-top: 4px;
  margin-left: 10px;
}

.buttonAddPractice {
  font-size: 11px;
}

.buttonAddPractice i {
  font-size: 16px;
}
</style>
