import { render, staticRenderFns } from "./AppointmentDialog.vue?vue&type=template&id=84af839e&scoped=true&"
import script from "./AppointmentDialog.vue?vue&type=script&lang=ts&"
export * from "./AppointmentDialog.vue?vue&type=script&lang=ts&"
import style0 from "./AppointmentDialog.vue?vue&type=style&index=0&id=84af839e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84af839e",
  null
  
)

export default component.exports