
















































































































































import TextCard from '@/components/cards/TextCard.vue';
import TreatmentNotesCard from '@/components/cards/TreatmentNotesCard.vue';
import { mdiClose } from '@mdi/js';
import { mdiPause } from '@mdi/js';
import { mdiPlay } from '@mdi/js';
import Vue from 'vue';
import {mapActions, mapState} from 'vuex';

export default Vue.extend({
  name: 'TreatmentDialog',
  components: { TextCard, TreatmentNotesCard },
  props: {
    name: String,
    administrator: { type: String, required: true},
    roomName: String,
    allocatedTime: Number,
    time: Number,
    cardState: String,
    notes: Array,
    appointment: { type: Object, required: true },
    appointmentId: String,
    treatment: { type: Object, required: true },
    isDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: true,
      closeIcon: mdiClose,
      pauseIcon: mdiPause,
      playIcon: mdiPlay,
      overHour: false,
      allocatedOverHour: false,
      doctorOfTreatment: null
    }
  },
  created(){
    this.doctorOfTreatment = this.doctors.find( k => k.uuid === this.administrator )
  },
  watch: {
    doctorOfTreatment: 'updateDoctor',
  },
  computed: {
    ...mapState({
      // @ts-ignore
      doctors: state => state.doctors,
    }),
    relatedDoctorsToTreatment()
    {
      let doctors = [];

      this.doctors.map( (doctor) => {

        doctor.treatments.some( ({ uuid }) => {
          if (uuid == this.treatment.treatment.uuid){
            doctors.push(doctor)
            return true
          }
        })
      })

      return doctors
    },
    stateIcon(): string {
      const playStates = ['default', 'completed'];
      return playStates.includes(this.cardState)
        ? this.playIcon
        : this.pauseIcon;
    },
    timeFormatted(): any {

      const time = (this.treatment.started_at && this.treatment.ended_at)
          ? this.$dayjs(this.treatment.ended_at).diff(this.$dayjs(this.treatment.started_at))
          : (this.time ? this.time : 0);

      const dur = this.$dayjs.duration(time).asMilliseconds();
      const format = dur >= 3600000 ? 'hh:mm' : 'mm:ss';
      this.overHour = format === 'hh:mm';
      return this.$dayjs.utc(dur).format(format);
    },
    allocatedTimeFormatted(): any {
      // MULTIPLY BY 60
      const dur = this.$dayjs.duration(this.allocatedTime).asMilliseconds();
      const format = dur >= 3600000 ? 'hh:mm' : 'mm:ss';
      this.allocatedOverHour = format === 'hh:mm';
      return this.$dayjs.utc(dur).format(format);
    },
  },
  methods: {
    ...mapActions([
      'UPDATE_DOCTOR_OF_APPOINTMENT_TREATMENT',
    ]),
    togglePlayState(event: any): void {
      event.target.blur();
      if (this.cardState === 'default') {
        this.$emit('play');
      } else {
        this.$emit('pause');
      }
    },
    handleCompleteTreatment() {
      this.$emit('complete-treatment');
      this.$emit('close');
    },
    async refreshAppointment() {
      const payload = {
        appointmentId: this.appointmentId,
      };
      await this.$store.dispatch('LOAD_DASHBOARD');

    },
    async updateDoctor() {

      if (this.doctorOfTreatment.uuid !== this.administrator){
        const result = await this.UPDATE_DOCTOR_OF_APPOINTMENT_TREATMENT({ appointmentId: this.appointmentId, params: {
            treatment: this.treatment.uuid,
            doctor: this.doctorOfTreatment.uuid,
          }})

        if (!result){
          this.doctorOfTreatment = this.administrator
        }
      }

    }
  }
});
