var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"ad-body",attrs:{"hide-overlay":"","max-width":"768px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"relative w-full gray lighten-1"},[_c('v-btn',{staticClass:"absolute",staticStyle:{"top":"8px","right":"8px"},attrs:{"color":"gray-primary","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.closeIcon))])],1),_c('div',{staticClass:"p-4 mx-auto h-full w-full overflow-y-scroll"},[_c('div',{staticClass:"text-h6 gray-primary--text mb-2"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"text-h6 gray-primary--text mb-2"},[_c('span',{staticClass:"gray-secondary--text"},[_vm._v(" Practitioner: ")]),_c('v-select',{style:({
              width: '250px',
              display: 'inline-block',
            }),attrs:{"items":_vm.relatedDoctorsToTreatment,"id":"doctorSelect","menu-props":{
              maxHeight: 'auto'
            }},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"text-xs font-bold text-center d-inline pa-1 rounded",style:({
                  backgroundColor: data.item.color,
                  color: _vm.$options.filters.foregroundColor(data.item.color),
                  marginRight: '10px !important',
                })},[_vm._v(" "+_vm._s(_vm.$options.filters.initials(data.item.name))+" ")]),_c('b',[_vm._v(_vm._s(data.item.name))])]}},{key:"item",fn:function(data){return [_c('div',{staticClass:"text-xs font-bold text-center d-inline pa-1 rounded",style:({
                  backgroundColor: data.item.color,
                  color: _vm.$options.filters.foregroundColor(data.item.color),
                  marginRight: '10px !important',
                })},[_vm._v(" "+_vm._s(_vm.$options.filters.initials(data.item.name))+" ")]),_c('b',[_vm._v(_vm._s(data.item.name))])]}}]),model:{value:(_vm.doctorOfTreatment),callback:function ($$v) {_vm.doctorOfTreatment=$$v},expression:"doctorOfTreatment"}}),_c('span',{staticClass:"ml-2 gray-secondary--text"},[_vm._v(" "+_vm._s(_vm.roomName)+" ")])],1),_c('v-divider'),_c('div',{staticClass:"flex flex-col items-center pt-4",staticStyle:{"height":"650px","overflow-y":"scroll"}},[_c('div',{staticClass:"text-h6 gray-secondary--text mb-2"},[_vm._v(" Time Spent With Patient: ")]),_c('div',{staticClass:"text-h4 font-weight-medium gray-primary--text mb-6"},[_vm._v(" "+_vm._s(_vm.timeFormatted)+_vm._s(_vm.overHour ? 'm' : 's')+" ")]),_c('div',{staticClass:"text-h6 gray-secondary--text mb-2"},[_vm._v(" Allocated Time ")]),_c('div',{staticClass:"flex items-center mb-6"},[_c('TextCard',{staticClass:"mr-4",attrs:{"small":"","text":"-1 min"},on:{"click":function($event){return _vm.$emit('update-allocated-time', -60)}}}),_c('div',{staticClass:"text-h4 font-weight-medium gray-primary--text"},[_vm._v(" "+_vm._s(_vm.allocatedTimeFormatted)+_vm._s(_vm.allocatedOverHour ? 'm' : 's')+" ")]),_c('TextCard',{staticClass:"ml-4",attrs:{"small":"","text":"+1 min"},on:{"click":function($event){return _vm.$emit('update-allocated-time', 60)}}})],1),_c('v-btn',{staticClass:"mb-6",attrs:{"color":"gray-primary","disabled":_vm.cardState === 'completed' || (
              _vm.appointment && _vm.appointment.treatments
                .filter(function (ref) {
                            var uuid = ref.uuid;

                            return uuid !== this$1.treatment.uuid;
})
                .some(function (ref) {
                            var a = ref.started_at;
                            var b = ref.ended_at;

                            return (!!a && !b);
})
            ) || _vm.isDisabled,"fab":"","outlined":""},on:{"click":function($event){return _vm.togglePlayState($event)}}},[_c('v-icon',{attrs:{"size":32}},[_vm._v(_vm._s(_vm.stateIcon))])],1),_c('div',{staticClass:"w-full flex justify-center px-2"},[_c('v-btn',{staticClass:"mr-6",attrs:{"color":"primary","disabled":(_vm.cardState === 'completed') || !_vm.treatment.started_at || _vm.isDisabled},on:{"click":_vm.handleCompleteTreatment}},[_vm._v(" COMPLETE TREATMENT ")]),_c('v-btn',{staticClass:"ml-6",attrs:{"color":"gray lighten-2","disabled":_vm.cardState === 'default' && !_vm.treatment.started_at || _vm.isDisabled},on:{"click":function($event){return _vm.$emit('reset-treatment')}}},[_vm._v(" RESET ")])],1),_c('v-divider',{staticClass:"w-full mt-4 mb-4"}),_c('TreatmentNotesCard',{attrs:{"hide-header":"","notes":_vm.notes,"treatment-id":_vm.treatment.uuid},on:{"refresh-notes":_vm.refreshAppointment}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }