



















import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'TimerCard',
  props: {
    appointment: { type: Object, required: true },
    appointmentState: { type: String, required: true },
    numberCompleted: { type: Number, required: true },
    numberOfTreatments: { type: Number, required: true },
    roomId: { type: String, required: true },
    isDisabled: {type: Boolean, default: false}
  },
  data() { return { treatmentState: 'inactive' }},
  computed: {
    ...mapState({ timeNow: ({ time: x }) => x }),
    isOverTime() { return this.timeElapsed > this.timeAllocated },
    isOverTimeAlmost() {
      return this.timeElapsed > (this.timeAllocated - 180000)
    },
    timeAllocated(): number {
      return this.appointment.treatments?.reduce(
        (acc: number, { duration: x }) => acc + (x * 1000),
        0
      ) ?? 0
    },
    timeElapsed(): number {
      const [startedAt = this.timeNow] = this.appointment.treatments
        .filter(({ started_at: x }) => !!x)
        .map(({ started_at: x }) => this.$dayjs.utc(x).valueOf())
        .sort()
      return this.timeNow - startedAt
    },
    timeInTreatment(): number {
      return this.appointment.treatments
        .map(({
          ended_at: timeEnded,
          resumed_at: timeResumedAt,
          started_at: timeStarted,
          status,
          time_elapsed: timeElapsedBeforeSeconds
        }) => {
          const isPaused = status === 'paused'
          const timeElapsedBefore = timeElapsedBeforeSeconds * 1000
          const timeResumed =
            (!!timeStarted && !isPaused && !!timeResumedAt)
              ? this.$dayjs.utc(timeResumedAt).valueOf()
              : 0
          const timeSinceResume =
            (!!timeStarted && !timeEnded && !isPaused)
              ? this.timeNow - timeResumed ?? this.timeNow
              : 0
          return timeElapsedBefore + timeSinceResume
        })
        .reduce((acc: number, cur: number) => acc + cur, 0)
    },
    borderColor() {
      if (this.numberCompleted >= this.numberOfTreatments)
        return 'border--complete'
      if (this.isOverTime) return 'border--alert'
      if (this.isOverTimeAlmost) return 'border--warn'
      return ''
    },
    timeTextColor(): string {
      return this.appointmentState === 'inactive'
        ? 'gray--text'
        : 'black--text';
    }
  },
  methods: {
    getTimeString(x: number /* milliseconds */) {

    },
    setTreatmentState(payload): void {
      const excludeCriterion = payload.state === 'completed' && (this.numberCompleted !== this.numberOfTreatments);
      if (excludeCriterion) return;
      this.treatmentState = payload.state;
    }
  }
});
