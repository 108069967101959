


























































import Vue from 'vue';
import {trim} from 'lodash';
import {mapState} from 'vuex';

export default Vue.extend({
  name: 'TreatmentNotesCard',
  props: {
    notes: Array,
    treatmentId: {
      type: String,
      default: ''
    },
    hideHeader: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      adding: false,
      newNote: '',
      error: false,
      hint: '',
      editedNotes: [],
      currentUser: '',
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      virtualKeyboardValues: (state) => state.virtualKeyboardValues,
      // @ts-ignore
      virtualKeyboardRelatedId: (state) => state.virtualKeyboardRelatedId,
    })
  },
  mounted() {
    this.copyModelFromProps()
  },
  watch: {
    notes() {
      this.copyModelFromProps();
    },
    virtualKeyboardValues() {
      const virtualKeyboardRelatedIdExploded = this.virtualKeyboardRelatedId.split('.-.')

      if (this.virtualKeyboardRelatedId === "note-treatmentDialogAdd"){
        this.newNote = this.virtualKeyboardValues
      } else if( virtualKeyboardRelatedIdExploded[0] === 'note' ){
        const key = parseInt(virtualKeyboardRelatedIdExploded[2])
        this.editedNotes[key].message = this.virtualKeyboardValues
      }
    },
    newNote(){
      if (this.newNote !== this.virtualKeyboardValues){
        console.log("newNote", this.virtualKeyboardValues)
        this.$store.dispatch('setKeyboardInput', { input: this.newNote })
      }
    }
  },
  created(){
    this.currentUser = this.$store.state.auth.user.uuid
    this.copyModelFromProps()
  },
  methods: {
    async createNote(event) {
      event.target.blur();
      const payload = {
        params: {
          message: this.newNote,
          appointment_treatment: this.treatmentId || this.notes[0].appointment_treatment.uuid
        }
      };
      const success = await this.$store.dispatch('CREATE_NOTE', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added note.' });
        this.newNote = '';
        this.adding = false;
        this.$emit('refresh-notes');
      } else {
        this.showError();
      }
    },
    handleFocusedInput(idOfRelated, message){
      if (this.virtualKeyboardRelatedId !== idOfRelated){
        this.$store.dispatch('setVirtualKeyboardRelatedId', { idOfRelated })
        this.$store.dispatch('setKeyboardInput', { input: message })
      }
    },
    clearNote() {
      this.newNote = '';
    },
    toggleOffAddingNote() {
      this.newNote = '';
      this.adding = false;
    },
    async editTreatmentNote(i) {
      this.editedNotes[i].message = trim(this.editedNotes[i].message)

      if (this.editedNotes[i].message !== this.notes[i].message) {
        const payload = {
          params: {
            uuid: this.editedNotes[i].uuid,
            message: this.editedNotes[i].message,
          }
        };
        const success = await this.$store.dispatch('UPDATE_NOTE', payload);
        if (success) {
          this.$eventBus.$emit('emit-success', {state: true, text: 'Note updated.'});
          await this.$emit('refresh-notes');
          this.copyModelFromProps();
        } else {
          this.$eventBus.$emit('emit-error', {state: true, text: 'Note could not update.'});
          this.copyModelFromProps();
        }
      }

    },
    async deleteTreatmentNote(i) {
      const noteToDelete = this.editedNotes[i].uuid

      if (confirm('Are you sure to delete current note?')){
        const payload = {
          params: {
            uuid: noteToDelete,
          }
        };
        const success = await this.$store.dispatch('DELETE_NOTE', payload);
        if (success) {
          this.$eventBus.$emit('emit-error', {state: true, text: 'Note succeed DELETED!'});
          await this.$emit('refresh-notes');
        } else {
          this.$eventBus.$emit('emit-error', {state: true, text: 'Note could not DELETE.'});
        }
      }
    },
    showError() {
        this.error = true;
        this.hint = 'Something went wrong. Please try again.'
        setTimeout(() => {
          this.error = false;
          this.hint = '';
        }, 3000);
    },
    patientName(name) {
      if (name.slice(0,3) === 'Dr.') {
        return name.slice(4);
      }
      return name;
    },
    copyModelFromProps() {
      this.editedNotes = JSON.parse(JSON.stringify(this.notes));
    }
  }
})
