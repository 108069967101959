
























































import Vue from 'vue';
import {mapState} from 'vuex';
import TreatmentNotesCard from '@/components/cards/TreatmentNotesCard.vue';

export default Vue.extend({
  name: 'NotesDialog',
  components: {
    TreatmentNotesCard,
  },
  data() {
    return {
      dialog: true,
      all: true,
    }
  },
  mounted() {
    this.$store.dispatch('LOAD_NOTES');
  },
  computed: {
    allNotes() {
      return this.$store.state.notes;
    },
    notes() {
      const notes = this.$_.groupBy(this.$store.state.notes, 'appointment_treatment.uuid');
      return notes;
    },
    display() {
      if (this.all) return this.notes;
      const myNotes = this.$store.state.notes.filter(note => note.user.uuid === this.$store.state.auth.user.uuid);
      return this.$_.groupBy(myNotes, 'appointment_treatment.uuid');
    }
  },
  methods: {
    isLast(treatmentUuid) {
      const treatmentCount = Object.keys(this.display).length;
      return treatmentUuid !== Object.keys(this.display)[treatmentCount - 1];
    }
  }
})
