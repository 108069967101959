<template>
  <div class="py-4 px-4 md:px-0 w-full lighten-1">
    <SettingsPracticeCard
        v-for="(practice, i) in practicesAll"
        :key="`practice-${practice.uuid}`"
        :uuid="practice.uuid"
        :name="practice.name"
        :enabled="practice.enabled === 1"
        :updatedAt="practice.updatedAt"
        :externalId="practice.drchronoId"
        :drchronoName="practice.drchronoName"
    />
    <v-dialog
        v-model="dialog"
        hide-overlay
        max-width="30%"
        persistent
    >
      <div class="relative w-full gray lighten-1 pa-lg-5">
        <v-btn
            class="absolute"
            icon
            style="top: 8px; right: 8px;"
            @click="$emit('close-dialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="title font-weight-bold">Add Practice</div>

        <div class="flex flex-col items-center pt-4">
          <v-select
              outlined
              class="practiceNameField"
              :items="practiceCandidates"
              item-text="name"
              item-value="id"
              v-model="practiceDrChronoOffice"
              label="Office on DrChrono"
              aria-required="true"
              placeholder="Select office on DrChrono"
          >
            <template slot="no-data">
              <div class="noDataContainer">All practices assigned</div>
            </template>
          </v-select>

          <div class="w-full flex justify-end">
            <v-btn
                color="#2F6B93"
                style="color: white; float: right"
                @click="addNewPractice"
            >
              <div class="flex items-center">
                <span class="mr-1">ADD</span>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SettingsPracticeCard from "../cards/SettingsPracticeCard";

export default {
  name: "PracticesSettings",
  components: { SettingsPracticeCard },
  props: {
    createPractice: { type: Boolean, default: false }
  },
  data() {
    return {
      dialog: false,
      practiceName: undefined,
      practiceDrChronoOffice: undefined,
    }
  },
  computed: {
    ...mapState({
      practicesAll: state => state.practicesAll,
      practiceCandidates: state => state.practiceCandidates,
    }),
  },
  mounted() {
    this.$store.dispatch('GET_ALL_PRACTICES')
  },
  watch: {
    createPractice() {
      this.$store.dispatch('GET_ALL_PRACTICES_CANDIDATES')
      this.dialog = this.createPractice;
    },
    practicesAll() {
      this.practicesAllForSelect = this.practicesAll;
    },
  },
  methods: {
    async addNewPractice() {

      const drChrono = this.practiceCandidates.filter( pC => pC.id === this.practiceDrChronoOffice)[0]
      console.log('drChrono - ', drChrono.name)

      const payload = {
        practiceUUID: this.uuid,
        data: {
          name: drChrono.name,
          drchronoName: drChrono.name,
          drchronoId: drChrono.id,
        }
      }

      const success = await this.$store.dispatch('PRACTICE_CREATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${this.practiceName} created! .` });
        this.clearForm()
        this.$emit('close-dialog')
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while creating on ${this.practiceName}.` });
      }

    },
    clearForm() {
      this.practiceName = undefined
    }
  }
}
</script>

<style scoped>
.practiceNameField {
  width: 100%;
}

.noDataContainer {
  padding: 10px 20px;
}
</style>
