















import Room from '@/components/modules/Room.vue'
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'Dashboard',
  components: { Room },
  data() {
    return {
      themes: ['#C9D4E2', '#C9D4E2', '#E9EEF7', '#E9EEF7', '#E3E8FF', '#E3E8FF', '#DCE6F7', '#E3F2FF', '#C9D4E2', '#C9D4E2', '#C9D4E2', '#C9D4E2']
    }
  },
  computed: {
    ...mapState({
      rooms: (state: any) => state.auth.dashboard.rooms,
    }),
    dashboard() {
      return this.$store.state.auth.dashboard;
    }
  },
  mounted() {
    this.scrollRoomsToRight();
  },
  methods: {
    scrollRoomsToRight() {
      const rooms = document.getElementById('rooms');
      rooms.scrollLeft += rooms.scrollWidth;
    },
  }
})
